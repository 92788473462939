import React from "react";
import { Link } from "react-router-dom";
import { getStorage } from "../../modals/Storage";
import {
  WEBSITE_LOGO_ORIGINAL,
  WEBSITE_NAME,
  openNewPage,
} from "../../modals/Constants";
import general from "../../css/General.module.css";
import styles from "../Footer/footer.module.css";

function Footer({ intentData }) {
  return (
    <div
      className={[
        styles.colView,
        styles.footerView,
        intentData.isShowFooter ? styles.hideView : "",
      ].join(" ")}
    >
      <div className={[styles.footerViewContents]}>
        <Link className={[styles.mLogoOriginal]} to={"/"}>
          {WEBSITE_NAME}
        </Link>
        <p
          className={[styles.clWhite, styles.fontSz13px, styles.mgT10px].join(
            " "
          )}
          dangerouslySetInnerHTML={{
            __html: getStorage("footerShortInfo"),
          }}
        ></p>

        <div className={[styles.footerViewOptions]}>
          <div className="col-view">
            <span>Imp Links</span>
            <Link
              className={[styles.fontSz13px, styles.pd5px0px].join(" ")}
              to={"/about-us"}
            >
              About us
            </Link>
            <Link
              className={[styles.fontSz13px, styles.pd5px0px].join(" ")}
              to={"/refund-policy"}
            >
              Refund Policy
            </Link>
            <Link
              className={[styles.fontSz13px, styles.pd5px0px].join(" ")}
              to={"/privacy-policy"}
            >
              Privacy Policy
            </Link>
            <Link
              className={[styles.fontSz13px, styles.pd5px0px].join(" ")}
              to={"/help-support"}
            >
              Help & Support
            </Link>
          </div>

          <div className="col-view">
            <span>Discover</span>
            {getStorage("categoryArr") != ""
              ? JSON.parse(getStorage("categoryArr")).map((data, index) => (
                  <Link
                    key={index}
                    className={[
                      styles.clWhite,
                      styles.fontSz13px,
                      styles.pd5px0px,
                    ].join(" ")}
                    to={"/c/" + data["category_slug"]}
                  >
                    {data["category_name"]}
                  </Link>
                ))
              : ""}
          </div>

          <div className="col-view">
            <span>Contact</span>
            <p
              className={[styles.fontSz13px, styles.pd5px0px].join(" ")}
              dangerouslySetInnerHTML={{
                __html: getStorage("footerContactInfo"),
              }}
            ></p>
          </div>
        </div>

        <div
          className={[
            general.rowView,
            general.jcSpaceBetween,
            general.mgT50px,
          ].join(" ")}
        >
          <Link
            className={[
              general.textDecoNone,
              general.viewcenter,
              general.fontSize16px,
            ].join(" ")}
            to={"/"}
          >
            <p className={[general.colorWhite]}>©{WEBSITE_NAME}</p>
          </Link>

          <div className={[general.rowView, general.opacity0p7].join(" ")}>
            <Link
              className={[
                general.textDecoNone,
                general.viewcenter,
                general.fontSize26px,
                general.colorWhite,
              ].join(" ")}
              onClick={() => openNewPage(getStorage("socialInstagram"))}
            >
              <i className="bx bxl-instagram-alt"></i>
            </Link>

            <Link
              className={[
                general.textDecoNone,
                general.viewcenter,
                general.fontSize26px,
                general.mgL10px,
                general.colorWhite,
              ].join(" ")}
              onClick={() => openNewPage(getStorage("socialYoutube"))}
            >
              <i className="bx bxl-youtube"></i>
            </Link>

            <Link
              className={[
                general.textDecoNone,
                general.viewcenter,
                general.fontSize26px,
                general.mgL10px,
                general.colorWhite,
              ].join(" ")}
              onClick={() => openNewPage(getStorage("socialFacebook"))}
            >
              <i className="bx bxl-facebook-circle"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
