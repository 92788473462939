import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/dialog";
import "../../MainStyle.css";
import {
  WEBSITE_NAME,
  API_ACCESS_URL,
  generateAuthToken,
  WEBSITE_URL,
} from "../modals/Constants";
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";
import UploadShowImage from "../other-components/UploadShowImage";
import { Player } from "@lottiefiles/react-lottie-player";
import ConfirmDialog from "../dialogs/ConfirmDialog/dialog";

function AddProperty() {
  const navigate = useNavigate();

  const [isPostPropertyEnabled, setPostPropertyEnabled] = useState(true);
  const [isFormValCorrect, setFormValCorrect] = useState(false);
  const [shouldShowError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [formStage, setFormStage] = useState(1);

  const [propertyId, setPropertyId] = useState("");
  const [propertyTitle, setPropertyTitle] = useState("");
  const [propertyDevelopedBy, setPropertyDevelopedBy] = useState("");
  const [propertyDescription, setPropertyDescription] = useState("");
  const [propertyBuildupArea, setBuildupArea] = useState("");
  const [propertyCarpetArea, setCarpetArea] = useState("");
  const [propertyPrice, setPropertyPrice] = useState("");
  const [propertyAge, setPropertyAge] = useState("");
  const [propertyFloor, setPropertyFloor] = useState("");
  const [propertyTotalFloors, setTotalFloors] = useState("");
  const [propertyLicense, setPropertyLicense] = useState("");
  const [parkingChargesType, setParkingChargesType] = useState("");
  const [propertyParkingCharges, setPropertyParkingCharges] = useState("");

  // form stage: 2
  const [propertyLocationCity, setLocationCity] = useState("");
  const [propertyCompleteLocaton, setCompleteLocation] = useState("");

  const [propertyType, setPropertyType] = useState("");
  const [propertyLookingTo, setPropertyLookingTo] = useState("");
  const [propertyTypeCategory, setPropertyTypeCategory] = useState("");
  const [propertyContructionStatus, setContructionStatus] = useState("");
  const [propertyFurnishStatus, setFurnishStatus] = useState("");
  const [propertySize, setPropertySize] = useState("");
  const [propertyBathrooms, setBathrooms] = useState("");
  const [propertyBalcony, setBalcony] = useState("");
  const [propertyCarParking, setCarParking] = useState("");
  const [propertyFacing, setFacing] = useState("");
  const [propertyImages, setPropertyImages] = useState([]);

  // required details
  const [cityList, setCityList] = useState(null);

  const [pageConst, setConstants] = useState({
    pageTitle: "Login",
    inPassword: "",
    isLoadingShow: false,
    isSessionExist: true,
  });

  const [toastDialog, setToastDialog] = useState({
    toastDialog: false,
    toastMessage: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    showDialog: false,
    dialogMessage: "",
  });

  const disableWheelMovement = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();
  };

  const removeItemFromArray = (array, index) => {
    if (index >= 0) {
      const newArray = array.toSpliced(index, 1);
      setPropertyImages(newArray);
    }
  };

  const onImageUpdate = (data) => {
    for (let i = 0; i < data.length; i++) {
      setPropertyImages((propertyImages) => [...propertyImages, data[i]]);
    }
  };

  const updateFormStage = () => {
    if (formStage < 4) {
      setFormValCorrect(false);
      setFormStage(formStage + 1);
    }
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const numDifferentiation = (data) => {
    if (data >= 10000000) data = (data / 10000000).toFixed(1) + " Cr";
    else if (data >= 100000) data = (data / 100000).toFixed(1) + " Lac";
    else if (data >= 1000) data = (data / 1000).toFixed(1) + " K";
    return data;
  };

  const updateConfirmDialogState = (data, msg) => {
    setConfirmDialog((previousState) => {
      return { ...previousState, showDialog: data };
    });

    setConfirmDialog((previousState) => {
      return { ...previousState, dialogMessage: msg };
    });
  };

  const updateConfirmDialogOnSubmit = (data, msg) => {
    if (data) {
      if (formStage == 1 || formStage == 2) {
        saveDetails();
      } else if (formStage == 3) {
        uploadImages();
      }
    }

    setConfirmDialog((previousState) => {
      return { ...previousState, dialogMessage: "" };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setToastDialog((previousState) => {
      return { ...previousState, toastDialog: data };
    });

    setToastDialog((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const proceedForm = () => {
    if (checkForInputs()) {
      updateConfirmDialogState(true, "Are all the details correct?");
    }
  };

  const checkForInputs = () => {
    let respValue = false;

    if (
      formStage == 1 &&
      propertyLookingTo == "SELL" &&
      (Number(propertyPrice) < 100000 || Number(propertyPrice) > 2000000000)
    ) {
      updateToastDialogState(
        true,
        "Property cost should be greater than 1Lakh and less than 200 crore."
      );
    } else if (
      formStage == 1 &&
      propertyLookingTo == "RENT" &&
      (Number(propertyPrice) < 1500 || Number(propertyPrice) > 2000000)
    ) {
      updateToastDialogState(
        true,
        "Monthly rent should be greater than 1500 and less than 20Lakhs."
      );
    } else if (
      formStage == 1 &&
      propertyLookingTo == "RENT" &&
      parkingChargesType == ""
    ) {
      updateToastDialogState(true, "Please enter parking charges.");
    } else if (
      formStage == 1 &&
      propertyLookingTo == "RENT" &&
      parkingChargesType == "SEPARATE" &&
      propertyParkingCharges <= 0
    ) {
      updateToastDialogState(true, "Please enter parking charges.");
    } else if (
      (formStage == 1 && Number(propertyBuildupArea) < 150) ||
      Number(propertyBuildupArea) > 1500
    ) {
      updateToastDialogState(
        true,
        "Buildup Area should be between 150 and 1500"
      );
    } else if (
      formStage == 1 &&
      propertyType != "" &&
      propertyLookingTo != "" &&
      propertyTypeCategory != "" &&
      propertySize != "" &&
      propertyContructionStatus != "" &&
      propertyFurnishStatus != "" &&
      propertyTitle != "" &&
      propertyDevelopedBy != "" &&
      propertyBuildupArea != "" &&
      propertyCarpetArea != "" &&
      propertyPrice != "" &&
      propertyAge != "" &&
      Number(propertyAge) <= 60 &&
      propertyFloor != "" &&
      propertyTotalFloors != "" &&
      propertyBathrooms != "" &&
      propertyBalcony != ""
    ) {
      respValue = true;
      setShowError(false);
      setFormValCorrect(true);
    } else if (formStage == 2 && propertyCompleteLocaton.length < 5) {
      updateToastDialogState(
        true,
        "Complete Location should be minimum 5 characters long."
      );
    } else if (
      formStage == 2 &&
      propertyLocationCity != "" &&
      propertyLocationCity != "Select City" &&
      propertyCompleteLocaton.length >= 5
    ) {
      respValue = true;
      setShowError(false);
      setFormValCorrect(true);
    } else if (formStage == 3 && propertyImages.length < 1) {
      console.log("here len: " + propertyImages.length);
      updateToastDialogState(true, "Please upload atleast 1 property image.");
    } else if (formStage == 3 && propertyImages.length >= 1) {
      respValue = true;
      setShowError(false);
      setFormValCorrect(true);
    } else if (formStage == 1 && Number(propertyAge) > 60) {
      updateToastDialogState(true, "Property age can't greater than 60.");
    } else {
      setShowError(true);
      setFormValCorrect(false);
      if (formStage == 3) {
        updateToastDialogState(true, "Please upload atleast 1 image.");
      } else {
        updateToastDialogState(
          true,
          "Please fillup all the star marked details."
        );
      }
    }

    return respValue;
  };

  const saveDetails = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            route: "route-submit-property",
          },
          body: formData,
        });

        updateLoadingStatus(false);
        const data = await res.json();
        if (data.status_code == "success") {
          setPropertyId(data.property_id);
          updateFormStage();
        } else {
          updateToastDialogState(true, "Please change the title to continue.");
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (isFormValCorrect) {
      updateLoadingStatus(true);
      let formData = new FormData();

      if (formStage == 1) {
        formData.append("ACCOUNT_ID", getStorage("accountID"));
        formData.append("FORM_STAGE", formStage);
        formData.append("PROPERTY_TITLE", propertyTitle);
        formData.append("PROPERTY_LOOKING_TO", propertyLookingTo);
        formData.append("PROPERTY_TYPE", propertyTypeCategory);
        formData.append("PROPERTY_DEVELOPED_BY", propertyDevelopedBy);
        formData.append("PROPERTY_DESCRIPTION", propertyDescription);
        formData.append("PROPERTY_CONFIGURATIONS", propertySize);
        formData.append("PROPERTY_BUILDUP_AREA", propertyBuildupArea);
        formData.append("PROPERTY_CARPET_AREA", propertyCarpetArea);
        formData.append("PROPERTY_COST", propertyPrice);
        formData.append("PROPERTY_FLOOR_NUMBER", propertyFloor);
        formData.append("PROPERTY_TOTAL_FLOORS", propertyTotalFloors);
        formData.append("PROPERTY_FURNISH_STATUS", propertyFurnishStatus);
        formData.append("PROPERTY_FACE_DIRECTION", propertyFacing);
        formData.append("PROPERTY_TOTAL_BATHROOMS", propertyBathrooms);
        formData.append("PROPERTY_TOTAL_BALCONIES", propertyBalcony);
        formData.append("PROPERTY_CAR_PARKING", propertyCarParking);
        formData.append("PROPERTY_CONSTRUCTION", propertyAge);
        formData.append(
          "PROPERTY_CONSTRUCTION_STATUS",
          propertyContructionStatus
        );
        formData.append("PROPERTY_LICENSE", propertyLicense);
        formData.append("PROPERTY_PARKING_CHARGES", propertyParkingCharges);
      } else if (formStage == 2) {
        formData.append("ACCOUNT_ID", getStorage("accountID"));
        formData.append("FORM_STAGE", formStage);
        formData.append("PROPERTY_ID", propertyId);
        formData.append("PROPERTY_LOCATION", propertyCompleteLocaton);
        formData.append("PROPERTY_LOCATION_CITY", propertyLocationCity);
      } else {
        formData.append("ACCOUNT_ID", getStorage("accountID"));
        formData.append("FORM_STAGE", formStage);
        formData.append("PROPERTY_ID", propertyId);
        formData.append("PROPERTY_IMAGES", propertyImages);
      }

      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const uploadImages = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            route: "route-upload-images",
          },
          body: formData,
        });

        updateLoadingStatus(false);
        const data = await res.json();
        if (data.status_code == "success") {
          setPropertyId(data.property_id);
          updateFormStage();
        } else {
          updateToastDialogState(true, "Duplicate data found!");
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (isFormValCorrect && formStage == 3) {
      updateLoadingStatus(true);

      let formData = new FormData();
      formData.append("ACCOUNT_ID", getStorage("accountID"));
      formData.append("FORM_STAGE", formStage);
      formData.append("PROPERTY_ID", propertyId);
      formData.append("TOTAL_IMAGES", propertyImages.length);
      propertyImages.forEach((image, index) => {
        formData.append(`PROPERTY_IMAGES_${index}`, image);
      });

      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const getRequiredDetails = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-required-details",
          },
          body: JSON.stringify(formData),
        });

        updateLoadingStatus(false);
        const data = await res.json();
        if (data.status_code == "success") {
          if (data.account_status == "inactive") {
            navigate("/account-update-instruction", { replace: true });
          } else if (data.account_status != "active") {
            if (deleteStorage()) {
              navigate("/signin", { replace: true });
            }
          }

          setCityList(data.cityList);
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (window.Handle) {
      setIsDataLoaded(true);
      setPostPropertyEnabled(false);
    } else if (!isDataLoaded) {
      setIsDataLoaded(true);
      updateLoadingStatus(true);
      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const openInBrowser = () => {
    if (window.Handle) {
      window.Handle.openInBrowser(WEBSITE_URL);
    }
  };

  useEffect(() => {
    if (getStorage("accountID") == "" || getStorage("accountSecret") == "") {
      navigate("/signin", { replace: true });
    } else {
      getRequiredDetails();
    }
  }, [cityList, formStage, shouldShowError]);

  return (
    <div
      className={[
        general.viewcenter,
        general.bgWhite,
        !isDataLoaded ? general.hideView : "",
      ].join(" ")}
    >
      <div
        className={[
          general.posRelative,
          general.w100,
          general.minheight100vh,
        ].join(" ")}
      >
        <ToastDialog
          intentData={toastDialog}
          updateState={updateToastDialogState}
        />
        <ConfirmDialog
          intentData={confirmDialog}
          updateState={updateConfirmDialogOnSubmit}
        />
        <LoadingDialog intentData={pageConst} />

        <div
          className={[
            general.pd10px,
            general.mgT10px,
            isPostPropertyEnabled ? general.hideView : "",
          ].join(" ")}
        >
          <div
            className={[
              general.colView,
              general.pd10px,
              general.borderRadius5px,
              general.border1pxSolidLightBlack,
            ].join(" ")}
          >
            <div
              className={[general.fontSize18px, general.fontWgt600].join(" ")}
            >
              Post your Property
              <label
                className={[
                  general.fontSize14px,
                  general.fontWgtNormal,
                  general.pd5px10px,
                  general.borderRadius5px,
                  general.colorWhite,
                  general.bgGreen,
                  general.mgL10px,
                ].join(" ")}
              >
                Free
              </label>
            </div>
            <span className={[general.fontSize12px, general.mgT5px].join(" ")}>
              Use browser to add new property.
            </span>
            <div
              className={[
                general.textAlignCenter,
                general.colorWhite,
                general.pd10px0px,
                general.bgBlue,
                general.borderRadius5px,
                general.mgT15px,
              ].join(" ")}
              onClick={() => openInBrowser()}
            >
              Open In Browser
            </div>
          </div>
        </div>

        <div
          className={[
            styles.addpropertyView,
            !isPostPropertyEnabled ? general.hideView : "",
            formStage >= 4 ? general.hideView : "",
          ].join(" ")}
        >
          <div className={[general.colView, general.pd15px].join(" ")}>
            <span className={[general.fontWgt600]}>Post your Property</span>
            <span className={[general.fontSize12px, general.mgT5px].join(" ")}>
              Sell or Rent your Property
            </span>

            <div className={[general.rowView, general.mgT30px].join(" ")}>
              <div
                className={[
                  general.viewcenter,
                  general.heightWidth23px,
                  general.borderRadius50,
                  formStage == 1
                    ? general.border2pxSolidBlue
                    : formStage > 1
                    ? general.bgGreen
                    : "",
                ].join(" ")}
              >
                <div
                  className={[
                    general.heightWidth15px,
                    general.borderRadius50,
                    formStage == 1 ? general.bgBlue : general.bgLightGrey,
                    formStage > 1 ? general.hideView : "",
                  ].join(" ")}
                ></div>

                <img
                  className={[
                    general.heightWidth23px,
                    formStage > 1 ? "" : general.hideView,
                  ].join(" ")}
                  src={require("../media/icons/check_icon_selected.png")}
                  alt="icon"
                />
              </div>

              <div className={[general.colView, general.mgL10px].join(" ")}>
                <span
                  className={[general.fontSize14px, general.fontWgt600].join(
                    " "
                  )}
                >
                  Property Details
                </span>
                <span
                  className={[
                    general.viewcenter,
                    general.wFitContent,
                    general.fontSize10px,
                    general.pd5px10px,
                    general.mgT5px,
                    general.borderRadius15px,
                    formStage > 1
                      ? general.bgCustomColor4
                      : general.bgLightGrey,
                  ].join(" ")}
                >
                  {formStage > 1 ? "Completed" : "In progress"}
                </span>
              </div>
            </div>

            <div
              className={[
                general.w2px,
                general.height30px,
                general.mgL10px,
                formStage > 1 ? general.bgGreen : general.bgLightGrey,
              ].join(" ")}
            ></div>

            <div className={[general.rowView].join(" ")}>
              <div
                className={[
                  general.viewcenter,
                  general.heightWidth23px,
                  general.borderRadius50,
                  formStage == 2
                    ? general.border2pxSolidBlue
                    : formStage > 2
                    ? general.bgGreen
                    : general.bgLightGrey,
                  ,
                ].join(" ")}
              >
                <div
                  className={[
                    general.heightWidth15px,
                    general.borderRadius50,
                    formStage == 2 ? general.bgBlue : general.bgLightGrey,
                    formStage > 2 ? general.hideView : "",
                  ].join(" ")}
                ></div>

                <img
                  className={[
                    general.heightWidth23px,
                    formStage > 2 ? "" : general.hideView,
                  ].join(" ")}
                  src={require("../media/icons/check_icon_selected.png")}
                  alt="icon"
                />
              </div>

              <div className={[general.colView, general.mgL10px].join(" ")}>
                <span
                  className={[general.fontSize14px, general.fontWgt600].join(
                    " "
                  )}
                >
                  Address
                </span>
                <span
                  className={[
                    general.viewcenter,
                    general.wFitContent,
                    general.fontSize10px,
                    general.pd5px10px,
                    general.mgT5px,
                    general.borderRadius15px,
                    formStage > 2
                      ? general.bgCustomColor4
                      : general.bgLightGrey,
                  ].join(" ")}
                >
                  {formStage > 2
                    ? "Completed"
                    : formStage > 1
                    ? "In progress"
                    : "Pending"}
                </span>
              </div>
            </div>

            <div
              className={[
                general.w2px,
                general.height30px,
                general.mgL10px,
                formStage > 2 ? general.bgGreen : general.bgLightGrey,
              ].join(" ")}
            ></div>

            <div className={[general.rowView].join(" ")}>
              <div
                className={[
                  general.viewcenter,
                  general.heightWidth23px,
                  general.borderRadius50,
                  formStage == 3
                    ? general.border2pxSolidBlue
                    : general.bgLightGrey,
                ].join(" ")}
              >
                <div
                  className={[
                    general.heightWidth15px,
                    general.borderRadius50,
                    formStage == 3 ? general.bgBlue : general.bgLightGrey,
                    formStage > 3 ? general.hideView : "",
                  ].join(" ")}
                ></div>

                <img
                  className={[
                    general.heightWidth23px,
                    formStage > 3 ? "" : general.hideView,
                  ].join(" ")}
                  src={require("../media/icons/check_icon_selected.png")}
                  alt="icon"
                />
              </div>

              <div className={[general.colView, general.mgL10px].join(" ")}>
                <span
                  className={[general.fontSize14px, general.fontWgt600].join(
                    " "
                  )}
                >
                  Photos
                </span>
                <span
                  className={[
                    general.viewcenter,
                    general.wFitContent,
                    general.fontSize10px,
                    general.pd5px10px,
                    general.mgT5px,
                    general.borderRadius15px,
                    formStage > 3
                      ? general.bgCustomColor4
                      : general.bgLightGrey,
                  ].join(" ")}
                >
                  {formStage > 3
                    ? "Completed"
                    : formStage > 2
                    ? "In progress"
                    : "Pending"}
                </span>
              </div>
            </div>

            <div
              className={[
                general.w2px,
                general.height30px,
                general.mgL10px,
                formStage > 3 ? general.bgGreen : general.bgLightGrey,
              ].join(" ")}
            ></div>

            <div className={[general.rowView].join(" ")}>
              <div
                className={[
                  general.viewcenter,
                  general.heightWidth23px,
                  general.borderRadius50,
                  formStage == 4
                    ? general.border2pxSolidBlue
                    : general.bgLightGrey,
                ].join(" ")}
              >
                <div
                  className={[
                    general.heightWidth15px,
                    general.borderRadius50,
                    formStage == 4 ? general.bgBlue : general.bgLightGrey,
                  ].join(" ")}
                ></div>
              </div>

              <div className={[general.colView, general.mgL10px].join(" ")}>
                <span
                  className={[general.fontSize14px, general.fontWgt600].join(
                    " "
                  )}
                >
                  Review
                </span>
                <span
                  className={[
                    general.viewcenter,
                    general.wFitContent,
                    general.fontSize10px,
                    general.pd5px10px,
                    general.mgT5px,
                    general.borderRadius15px,
                    formStage > 4
                      ? general.bgCustomColor4
                      : general.bgLightGrey,
                  ].join(" ")}
                >
                  {formStage > 4
                    ? "Completed"
                    : formStage > 3
                    ? "In progress"
                    : "Pending"}
                </span>
              </div>
            </div>
          </div>

          <div className={[general.bgWhite].join(" ")}>
            {/* stage: property details  */}
            <div className={[formStage != 1 ? general.hideView : ""].join(" ")}>
              <span
                className={[
                  general.fontSize18px,
                  general.fontWgt600,
                  general.pd10px0px,
                  general.borderBottom1px,
                ].join(" ")}
              >
                Add property Details
                <label
                  className={[
                    general.fontSize14px,
                    general.fontWgtNormal,
                    general.pd5px10px,
                    general.borderRadius5px,
                    general.colorWhite,
                    general.bgGreen,
                    general.mgL10px,
                  ].join(" ")}
                >
                  Free
                </label>
              </span>

              <p
                className={[
                  general.fontSize13px,
                  general.mgT40px,
                  propertyType == "" && shouldShowError ? general.colorRed : "",
                ].join(" ")}
              >
                Property Type
                <label className={[general.colorRed].join(" ")}>*</label>
              </p>

              <div
                className={[styles.selectOptionsView, general.mgT10px].join(
                  " "
                )}
              >
                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyType == "RESIDENTIAL" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertyType("RESIDENTIAL")}
                >
                  Residential
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyType == "COMMERCIAL" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertyType("COMMERCIAL")}
                >
                  Commercial
                </div>
              </div>

              <p
                className={[
                  general.fontSize13px,
                  general.mgT30px,
                  propertyLookingTo == "" && shouldShowError
                    ? general.colorRed
                    : "",
                ].join(" ")}
              >
                Looking To
                <label className={[general.colorRed].join(" ")}>*</label>
              </p>

              <div
                className={[styles.selectOptionsView, general.mgT10px].join(
                  " "
                )}
              >
                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyLookingTo == "RENT" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertyLookingTo("RENT")}
                >
                  Rent
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyLookingTo == "SELL" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertyLookingTo("SELL")}
                >
                  Sell
                </div>
              </div>

              <p
                className={[
                  general.fontSize13px,
                  general.mgT30px,
                  propertyTypeCategory == "" && shouldShowError
                    ? general.colorRed
                    : "",
                ].join(" ")}
              >
                Property Type
                <label className={[general.colorRed].join(" ")}>*</label>
              </p>

              <div
                className={[styles.selectOptionsView, general.mgT10px].join(
                  " "
                )}
              >
                <div
                  className={[
                    general.height80pxWidth90px,
                    propertyTypeCategory == "APARTMENT"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setPropertyTypeCategory("APARTMENT")}
                >
                  <img
                    className={[general.heightWidth23px]}
                    src={require("../media/icons/property_types/apartment_icon.png")}
                    alt="icon"
                  />
                  <span
                    className={[
                      general.fontSize12px,
                      general.textAlignCenter,
                    ].join(" ")}
                  >
                    Apartment
                  </span>
                </div>

                <div
                  className={[
                    general.height80pxWidth90px,
                    propertyTypeCategory == "INDEPENDENT FLOOR"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setPropertyTypeCategory("INDEPENDENT FLOOR")}
                >
                  <img
                    className={[general.heightWidth23px]}
                    src={require("../media/icons/property_types/independent_floor.png")}
                    alt="icon"
                  />
                  <span
                    className={[
                      general.fontSize12px,
                      general.textAlignCenter,
                    ].join(" ")}
                  >
                    Independent<br></br>Floor
                  </span>
                </div>

                <div
                  className={[
                    general.height80pxWidth90px,
                    propertyTypeCategory == "INDEPENDENT HOUSE"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setPropertyTypeCategory("INDEPENDENT HOUSE")}
                >
                  <img
                    className={[general.heightWidth23px]}
                    src={require("../media/icons/property_types/independent_house.png")}
                    alt="icon"
                  />
                  <span
                    className={[
                      general.fontSize12px,
                      general.textAlignCenter,
                    ].join(" ")}
                  >
                    Independent<br></br>House
                  </span>
                </div>

                <div
                  className={[
                    general.height80pxWidth90px,
                    propertyTypeCategory == "VILLA" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertyTypeCategory("VILLA")}
                >
                  <img
                    className={[general.heightWidth23px]}
                    src={require("../media/icons/property_types/villa_icon.png")}
                    alt="icon"
                  />
                  <span
                    className={[
                      general.fontSize12px,
                      general.textAlignCenter,
                    ].join(" ")}
                  >
                    Villa
                  </span>
                </div>

                <div
                  className={[
                    general.height80pxWidth90px,
                    propertyTypeCategory == "PLOT" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertyTypeCategory("PLOT")}
                >
                  <img
                    className={[general.heightWidth23px]}
                    src={require("../media/icons/property_types/plot_icon.png")}
                    alt="icon"
                  />
                  <span
                    className={[
                      general.fontSize12px,
                      general.textAlignCenter,
                    ].join(" ")}
                  >
                    Plot
                  </span>
                </div>
              </div>

              <p
                className={[
                  general.fontSize13px,
                  general.mgT30px,
                  propertySize == "" && shouldShowError ? general.colorRed : "",
                ].join(" ")}
              >
                BHK <label className={[general.colorRed]}>*</label>
              </p>

              <div
                className={[styles.selectOptionsView, general.mgT10px].join(
                  " "
                )}
              >
                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "1 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("1 BHK")}
                >
                  1 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "2 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("2 BHK")}
                >
                  2 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "3 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("3 BHK")}
                >
                  3 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "4 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("4 BHK")}
                >
                  4 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "5 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("5 BHK")}
                >
                  5 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "6 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("6 BHK")}
                >
                  6 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "7 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("7 BHK")}
                >
                  7 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == " 8BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("8 BHK")}
                >
                  8 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "9 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("9 BHK")}
                >
                  9 BHK
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertySize == "10 BHK" ? styles.activeOptions : "",
                  ].join(" ")}
                  onClick={() => setPropertySize("10 BHK")}
                >
                  10 BHK
                </div>
              </div>

              <p
                className={[
                  general.fontSize13px,
                  general.mgT30px,
                  propertyContructionStatus == "" && shouldShowError
                    ? general.colorRed
                    : "",
                ].join(" ")}
              >
                Construction Status{" "}
                <label className={[general.colorRed]}>*</label>
              </p>

              <div
                className={[styles.selectOptionsView, general.mgT10px].join(
                  " "
                )}
              >
                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyContructionStatus == "UNDER CONSTRUCTION"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setContructionStatus("UNDER CONSTRUCTION")}
                >
                  Under Construction
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyContructionStatus == "READY TO MOVE"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setContructionStatus("READY TO MOVE")}
                >
                  Ready To Move
                </div>
              </div>

              <p
                className={[
                  general.fontSize13px,
                  general.mgT30px,
                  propertyFurnishStatus == "" && shouldShowError
                    ? general.colorRed
                    : "",
                ].join(" ")}
              >
                Furnish Type <label className={[general.colorRed]}>*</label>
              </p>

              <div
                className={[styles.selectOptionsView, general.mgT10px].join(
                  " "
                )}
              >
                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyFurnishStatus == "FULLY FURNISHED"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setFurnishStatus("FULLY FURNISHED")}
                >
                  Fully Furnished
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyFurnishStatus == "SEMI FURNISHED"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setFurnishStatus("SEMI FURNISHED")}
                >
                  Semi Furnished
                </div>

                <div
                  className={[
                    general.heightAutoWidthFitContent,
                    general.fontSize14px,
                    propertyFurnishStatus == "UNFURNISHED"
                      ? styles.activeOptions
                      : "",
                  ].join(" ")}
                  onClick={() => setFurnishStatus("UNFURNISHED")}
                >
                  Unfurnished
                </div>
              </div>

              <div className={[styles.inputOptionsViews]}>
                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyTitle == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    Property Title{" "}
                    <label className={[general.colorRed]}>*</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyTitle}
                      autoComplete="off"
                      onChange={(e) => setPropertyTitle(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyDevelopedBy == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    Property Developed By
                    <label className={[general.colorRed]}>*</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyDevelopedBy}
                      autoComplete="off"
                      onChange={(e) => setPropertyDevelopedBy(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyBuildupArea == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    Property Build Up Area
                    <label className={[general.colorRed]}>(in sq.ft) *</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="number"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyBuildupArea}
                      autoComplete="off"
                      onWheel={disableWheelMovement}
                      onChange={(e) => setBuildupArea(e.target.value)}
                    ></input>
                  </div>
                  <span
                    className={[general.fontSize12px, general.mgT5px].join(" ")}
                  >
                    Area should be between 150 and 1500
                  </span>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyCarpetArea == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    Property Carpet Area
                    <label className={[general.colorRed]}>(in sq.ft)</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyCarpetArea}
                      autoComplete="off"
                      onWheel={disableWheelMovement}
                      onChange={(e) => setCarpetArea(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyPrice == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    {propertyLookingTo == "RENT"
                      ? "Monthly Rent"
                      : "Property Cost"}
                    <label className={[general.colorRed]}>*</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="number"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyPrice}
                      autoComplete="off"
                      onWheel={disableWheelMovement}
                      onChange={(e) => setPropertyPrice(e.target.value)}
                    ></input>
                  </div>
                  <span
                    className={[general.fontSize12px, general.mgT5px].join(" ")}
                  >
                    {propertyLookingTo == "RENT"
                      ? "Price should be between 1500 and 20 Lakhs:"
                      : "Price should be between 1 Lakh and 200 Crore:"}

                    {numDifferentiation(propertyPrice)}
                  </span>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyAge == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    Age of Property
                    <label className={[general.colorRed]}>(in Years) *</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="number"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyAge}
                      autoComplete="off"
                      onChange={(e) => setPropertyAge(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyFloor == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    Floor No. <label className={[general.colorRed]}>*</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyFloor}
                      autoComplete="off"
                      onChange={(e) => setPropertyFloor(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.fontSize13px,
                      propertyTotalFloors == "" && shouldShowError
                        ? general.colorRed
                        : "",
                    ].join(" ")}
                  >
                    Total Floors<label className={[general.colorRed]}>*</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyTotalFloors}
                      autoComplete="off"
                      onChange={(e) => setTotalFloors(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span className={[general.fontSize13px]}>License</span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyLicense}
                      autoComplete="off"
                      onChange={(e) => setPropertyLicense(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>

              <div className={[general.mgT30px]}>
                <span
                  className={[
                    general.fontSize16px,
                    general.fontWgt600,
                    general.pd10px0px,
                    general.borderBottom1px,
                  ].join(" ")}
                >
                  Additional Information
                </span>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span className={[general.fontSize13px].join(" ")}>
                    Property Description
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyDescription}
                      autoComplete="off"
                      onChange={(e) => setPropertyDescription(e.target.value)}
                    ></input>
                  </div>
                </div>

                <p
                  className={[general.fontSize13px, general.mgT30px].join(" ")}
                >
                  Facing
                </p>

                <div
                  className={[styles.selectOptionsView, general.mgT10px].join(
                    " "
                  )}
                >
                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "NORTH" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setFacing("NORTH")}
                  >
                    North
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "SOUTH" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setFacing("SOUTH")}
                  >
                    South
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "EAST" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setFacing("EAST")}
                  >
                    East
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "WEST" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setFacing("WEST")}
                  >
                    West
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "NORTH-EAST"
                        ? styles.activeOptions
                        : "",
                    ].join(" ")}
                    onClick={() => setFacing("NORTH-EAST")}
                  >
                    North-East
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "NORTH-WEST"
                        ? styles.activeOptions
                        : "",
                    ].join(" ")}
                    onClick={() => setFacing("NORTH-WEST")}
                  >
                    North-West
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "SOUTH-EAST"
                        ? styles.activeOptions
                        : "",
                    ].join(" ")}
                    onClick={() => setFacing("SOUTH-EAST")}
                  >
                    South-East
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyFacing == "SOUTH-WEST"
                        ? styles.activeOptions
                        : "",
                    ].join(" ")}
                    onClick={() => setFacing("SOUTH-WEST")}
                  >
                    South-West
                  </div>
                </div>

                <p
                  className={[
                    general.fontSize13px,
                    general.mgT30px,
                    propertyBathrooms == "" && shouldShowError
                      ? general.colorRed
                      : "",
                  ].join(" ")}
                >
                  Bathroom <label className={[general.colorRed]}>*</label>
                </p>

                <div
                  className={[styles.selectOptionsView, general.mgT10px].join(
                    " "
                  )}
                >
                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBathrooms == "1" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBathrooms("1")}
                  >
                    1
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBathrooms == "2" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBathrooms("2")}
                  >
                    2
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBathrooms == "3" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBathrooms("3")}
                  >
                    3
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBathrooms == "4" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBathrooms("4")}
                  >
                    4
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBathrooms == "5" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBathrooms("5")}
                  >
                    5
                  </div>
                </div>

                <p
                  className={[
                    general.fontSize13px,
                    general.mgT30px,
                    propertyBalcony == "" && shouldShowError
                      ? general.colorRed
                      : "",
                  ].join(" ")}
                >
                  Balcony <label className={[general.colorRed]}>*</label>
                </p>

                <div
                  className={[styles.selectOptionsView, general.mgT10px].join(
                    " "
                  )}
                >
                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBalcony == "1" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBalcony("1")}
                  >
                    1
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBalcony == "2" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBalcony("2")}
                  >
                    2
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBalcony == "3" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBalcony("3")}
                  >
                    3
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBalcony == "4" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBalcony("4")}
                  >
                    4
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyBalcony == "5" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setBalcony("5")}
                  >
                    5
                  </div>
                </div>

                <p
                  className={[
                    general.fontSize13px,
                    general.mgT30px,
                    propertyCarParking == "" && shouldShowError
                      ? general.colorRed
                      : "",
                    propertyLookingTo == "RENT" ? general.hideView : "",
                  ].join(" ")}
                >
                  Car Parking <label className={[general.colorRed]}>*</label>
                </p>

                <div
                  className={[
                    styles.selectOptionsView,
                    general.mgT10px,
                    propertyLookingTo == "RENT" ? general.hideView : "",
                  ].join(" ")}
                >
                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyCarParking == "1" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setCarParking("1")}
                  >
                    1
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyCarParking == "2" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setCarParking("2")}
                  >
                    2
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyCarParking == "3" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setCarParking("3")}
                  >
                    3
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyCarParking == "4" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setCarParking("4")}
                  >
                    4
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      propertyCarParking == "5" ? styles.activeOptions : "",
                    ].join(" ")}
                    onClick={() => setCarParking("5")}
                  >
                    5
                  </div>
                </div>

                <p
                  className={[
                    general.fontSize13px,
                    general.mgT30px,
                    propertyCarParking == "" && shouldShowError
                      ? general.colorRed
                      : "",
                    propertyLookingTo != "RENT" ? general.hideView : "",
                  ].join(" ")}
                >
                  Parking Charges{" "}
                  <label className={[general.colorRed]}>*</label>
                </p>

                <div
                  className={[
                    styles.selectOptionsView,
                    general.mgT10px,
                    propertyLookingTo != "RENT" ? general.hideView : "",
                  ].join(" ")}
                >
                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      parkingChargesType == "INCLUDE IN RENT"
                        ? styles.activeOptions
                        : "",
                    ].join(" ")}
                    onClick={() => setParkingChargesType("INCLUDE IN RENT")}
                  >
                    Include in rent
                  </div>

                  <div
                    className={[
                      general.heightAutoWidthFitContent,
                      general.fontSize14px,
                      parkingChargesType == "SEPARATE"
                        ? styles.activeOptions
                        : "",
                    ].join(" ")}
                    onClick={() => setParkingChargesType("SEPARATE")}
                  >
                    Separate
                  </div>
                </div>

                <div
                  className={[
                    general.rowView,
                    general.w100,
                    general.mgT10px,
                    general.borderRadius5px,
                    general.border1pxSolidLightBlack,
                    propertyLookingTo == "RENT" &&
                    parkingChargesType == "SEPARATE"
                      ? ""
                      : general.hideView,
                  ].join(" ")}
                >
                  <input
                    type="text"
                    className={[
                      general.w100,
                      general.inpBox,
                      general.fontSize16px,
                      general.hideBorderBottom,
                    ].join(" ")}
                    value={propertyParkingCharges}
                    placeholder="Parking Charges (Per Month)"
                    autoComplete="off"
                    onChange={(e) => setPropertyParkingCharges(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            {/* stage: address  */}
            <div className={[formStage != 2 ? general.hideView : ""].join(" ")}>
              <span
                className={[
                  general.fontSize16px,
                  general.fontWgt600,
                  general.pd10px0px,
                  general.borderBottom1px,
                ].join(" ")}
              >
                Add Address
              </span>

              <div className={[styles.inputOptionsViews]}>
                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span className={[general.fontSize13px]}>
                    Property Location{" "}
                    <label className={[general.colorRed]}>City *</label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <select
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      onChange={(e) => setLocationCity(e.target.value)}
                    >
                      <option>Select City</option>
                      {cityList != null &&
                        cityList.map((data, index) => (
                          <option key={data["city_name"]}>
                            {data["city_name"]}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div
                  className={[
                    general.colView,
                    general.inpContainer,
                    general.mgT40px,
                  ].join(" ")}
                >
                  <span className={[general.fontSize13px]}>
                    Property Address{" "}
                    <label className={[general.colorRed]}>
                      Complete Address. *
                    </label>
                  </span>
                  <div
                    className={[
                      general.rowView,
                      general.w100,
                      general.mgT10px,
                      general.borderRadius5px,
                      general.border1pxSolidLightBlack,
                    ].join(" ")}
                  >
                    <input
                      type="text"
                      className={[
                        general.w100,
                        general.inpBox,
                        general.fontSize16px,
                        general.hideBorderBottom,
                      ].join(" ")}
                      value={propertyCompleteLocaton}
                      autoComplete="off"
                      onChange={(e) => setCompleteLocation(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </div>

            {/* stage: photos  */}
            <div className={[formStage != 3 ? general.hideView : ""].join(" ")}>
              <span
                className={[
                  general.fontSize16px,
                  general.fontWgt600,
                  general.pd10px0px,
                  general.borderBottom1px,
                ].join(" ")}
              >
                Add Address
              </span>

              <div className={[general.mgT40px].join(" ")}>
                <UploadShowImage
                  onImageUpdate={onImageUpdate}
                  onImageChanges={propertyImages}
                  showNewMessage={updateToastDialogState}
                />

                <div
                  className={[
                    styles.selectOptionsView,
                    general.gap15px,
                    general.mgT30px,
                  ].join(" ")}
                >
                  {/* {console.log(propertyImages)} */}
                  {propertyImages != null &&
                    propertyImages.map((imageSrc, index) => (
                      <div key={index}>
                        <div
                          className={[
                            general.viewcenter,
                            styles.cancelBtn,
                          ].join(" ")}
                          onClick={() =>
                            removeItemFromArray(propertyImages, index)
                          }
                        >
                          <i className="bx bx-x"></i>
                        </div>
                        <img
                          className={[general.height100px].join(" ")}
                          src={URL.createObjectURL(imageSrc)}
                          alt="icon"
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div
              className={[
                general.viewcenter,
                general.pd15px30px,
                general.colorWhite,
                general.borderRadius5px,
                general.bgBlue,
                general.mgT30px,
              ].join(" ")}
              onClick={() => proceedForm()}
            >
              Save & Continue
            </div>
          </div>
        </div>

        <div
          className={[
            general.w100,
            general.colView,
            general.alignItemsCenter,
            formStage < 4 ? general.hideView : "",
          ].join(" ")}
        >
          <Player
            src={require("../media/animations/order_confirmed_anim.json")}
            className="player w-125-p"
            loop
            autoplay
          />
          <h3 className="ft-sz-20 mg-t-10">Property Submitted!</h3>
          <span
            className={[
              general.fontSize12px,
              general.textAlignCenter,
              general.mgT10px,
            ].join(" ")}
          >
            Thankyou! We will verify your details and update<br></br>your
            listing.
          </span>
          <Link
            className="txt-deco-n cl-blue ft-sz-13 ft-wgt-600 mg-t-15"
            to={"/"}
          >
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddProperty;
