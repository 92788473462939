import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";
import general from "../../css/General.module.css";
import styles from "../ContainerV11Modal/Container.module.css";
import { WEBSITE_URL, openNewPage } from "../../modals/Constants";

function ContainerV11Modal({ dataList }) {
  const openCallerApp = (data) => {
    if (window.Handle && data != "") {
      window.Handle.openInBrowser("tel:" + data);
    } else if (data != "") {
      openNewPage("tel:" + data);
    }
  };

  return (
    <>
      {dataList.map((data, index) => (
        <div key={index} className={[styles.containerItem]}>
          <label
            className={[
              general.fontSize14px,
              general.fontWgtNormal,
              general.pd5px10px,
              general.borderRadius5px,
              general.colorWhite,
              general.mgL10px,
              data["record_request"] == "rejected"
                ? general.bgRed
                : data["record_request"] == "accepted"
                ? general.bgGreen
                : general.bgOrange,
            ].join(" ")}
          >
            {data["record_request"]}
          </label>
          <div
            className={[styles.containerImageBox]}
            onClick={() =>
              openNewPage(WEBSITE_URL + "/p/" + data["property_slug"])
            }
          >
            <ImageLoader
              className={[styles.containerImageView]}
              src={data["property_image"]}
              alt={data["property_title"]}
            />
          </div>

          <div className={[styles.containerContentsView]}>
            <span className={[styles.priceTxtView]}>
              ₹{data["property_price"]}
            </span>

            <span className={[styles.titleView]}>{data["property_title"]}</span>
            <span className={[styles.locationTxtview]}>
              {data["property_location"]}
            </span>

            <div className={[styles.containerOverView]}>
              <div className={[styles.overViewDetails]}>
                <div>
                  <i className="bx bx-area"></i>
                </div>
                <div className={[styles.overViewTxt]}>
                  <div>Carpet Area</div>
                  <div>{data["property_carpet_area"]}</div>
                </div>
              </div>

              <div className={[styles.overViewDetails]}>
                <div>
                  <i className="bx bx-building-house"></i>
                </div>
                <div className={[styles.overViewTxt]}>
                  <div>Status</div>
                  <div>{data["property_furnish_status"]}</div>
                </div>
              </div>
            </div>

            <div
              className={[
                general.w100,
                general.rowView,
                general.jcSpaceBetween,
                general.mgT30px,
              ].join(" ")}
            >
              <div className={[styles.developerDetailsView]}>
                <span>{data["property_owner_name"]}</span>
                <span
                  className={[
                    general.fontSize15px,
                    general.fontWgt500,
                    general.mgT10px,
                  ].join(" ")}
                >
                  {data["property_owner_phone_number"] != ""
                    ? "+91 " + data["property_owner_phone_number"]
                    : ""}
                </span>
                <span
                  className={[
                    general.viewcenter,
                    general.alignSelfFlexStart,
                    general.fontWgt500,
                    general.mgT10px,
                  ].join(" ")}
                >
                  Owner <i className="bx bx-chevron-right"></i>
                </span>
              </div>

              <Link
                className={[styles.contactBtn, general.textDecoNone].join(" ")}
                onClick={() =>
                  openCallerApp(data["property_owner_phone_number"])
                }
              >
                <i className="bx bx-phone"></i>
                <span>Contact Seller</span>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ContainerV11Modal;
