import React, { useState } from "react";
import styles from "../ContactDialog/Styles.module.css";
import general from "../../css/General.module.css";
import { getStorage } from "../../modals/Storage";
import { API_ACCESS_URL } from "../../modals/Constants";

const ContactDialog = ({ intentData, updateState, requestedProperty }) => {
  const [inputName, setInputName] = useState(getStorage("accountFullName"));
  const [inputPhoneNumber, setInputPhoneNumber] = useState(
    getStorage("accountMobile")
  );
  const [inputEmailId, setInputEmailId] = useState(getStorage("accountEmail"));

  function submitRequest() {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-contact-seller",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();

        if (data.status_code == "success") {
          updateState(false, "submitted");
        } else if (data.status_code == "already_pending") {
          updateState(false, "already_pending");
        } else {
          updateState(false, "");
        }
      } catch (error) {
        updateState(false, "");
      }
    }

    if (
      getStorage("accountID") != "" &&
      inputName != "" &&
      inputPhoneNumber != "" &&
      inputEmailId != "" &&
      requestedProperty != ""
    ) {
      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
        PROPERTY_ID: requestedProperty,
        CONTACT_NAME: inputName,
        CONTACT_PHONE: inputPhoneNumber,
        CONTACT_EMAIL: inputEmailId,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  return (
    <div
      className={[
        styles.dialogView,
        `${intentData.showDialog ? "" : styles.dialogViewHide}`,
      ].join(" ")}
    >
      <div className={[styles.dialogContainer]}>
        <div
          className={[styles.dismissBtn]}
          onClick={() => updateState(false, "")}
        >
          <span>✕</span>
        </div>
        <div className={[general.mgT30px]}>
          <div
            className={[
              general.colView,
              general.inpContainer,
              general.mgT5px,
            ].join(" ")}
          >
            <span className={[general.fontSize13px]}>Name*</span>
            <input
              type="text"
              className={[
                general.inpBox,
                general.fontSize16px,
                general.mgT5px,
              ].join(" ")}
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
            ></input>
          </div>

          <div
            className={[
              general.colView,
              general.inpContainer,
              general.mgT20px,
            ].join(" ")}
          >
            <span className={[general.fontSize13px]}>Phone Number*</span>
            <input
              type="number"
              className={[
                general.inpBox,
                general.fontSize16px,
                general.mgT5px,
              ].join(" ")}
              value={inputPhoneNumber}
              onChange={(e) => setInputPhoneNumber(e.target.value)}
            ></input>
          </div>

          <div
            className={[
              general.colView,
              general.inpContainer,
              general.mgT20px,
            ].join(" ")}
          >
            <span className={[general.fontSize13px]}>Email*</span>
            <input
              type="email"
              className={[
                general.inpBox,
                general.fontSize16px,
                general.mgT5px,
              ].join(" ")}
              value={inputEmailId}
              onChange={(e) => setInputEmailId(e.target.value)}
            ></input>
          </div>

          {getStorage("accountID") == "" ? (
            <div
              className={[
                general.pd15px,
                general.fontSize13px,
                general.borderRadius10px,
                general.bgCustomColor5,
                general.mgT15px,
              ].join(" ")}
            >
              <i className="bx bx-bulb"></i>
              <span className={[general.mgL5px]}>
                Please signin with your account to send contact request.
              </span>
            </div>
          ) : (
            <div
              className={[
                general.viewcenter,
                general.pd10px30px,
                general.colorWhite,
                general.mgT30px,
                general.fontWgt500,
                general.borderRadius5px,
                general.bgBlue,
              ].join(" ")}
              onClick={() => submitRequest()}
            >
              Get Contact Details
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactDialog;
