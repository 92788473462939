import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import styles from "../ContainerV7Modal/Container.module.css";

function ContainerV7Modal({ dataList }) {
  return (
    // <div className={[styles.containerView]}>
    <Splide
      options={{
        perPage: 5,
        gap: "1rem",
        pagination: false,
        breakpoints: {
          980: {
            perPage: 4,
          },
          750: {
            perPage: 3,
          },
          550: {
            perPage: 2,
          },
          370: {
            perPage: 1,
          },
        },
      }}
    >
      {dataList.map((data, index) => (
        <SplideSlide>
          <Link
            key={index}
            className={[styles.containerItem]}
            to={"search/" + data["item_name"]}
          >
            <div className={[styles.containerImageBox]}>
              <ImageLoader
                className={[styles.containerImageView]}
                src={data["item_image"]}
                alt={data["item_name"]}
              />
            </div>

            <div className={[styles.containerContentsView]}>
              <span className={[styles.titleView]}>{data["item_name"]}</span>

              <div className={[styles.moreDetailsView]}>
                <span>View More</span>
                <img
                  src={require("../../media/icons/arrow_right_up_icon.png")}
                  alt="icon"
                />
              </div>
            </div>
          </Link>
        </SplideSlide>
      ))}
    </Splide>
    // </div>
  );
}

export default ContainerV7Modal;
