import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";
import general from "../../css/General.module.css";
import styles from "../MessageView/messageview.module.css";

function MessageView({ intentData }) {
  return (
    <div className={[styles.messageView]}>
      <Link className={[styles.contentsView]} to={"/my-requests"}>
        <div className={[general.posRelative]}>
          <img
            src={require("../../media/illustrations/property_icon.png")}
            alt="icon"
          />
          <label className={[general.bgGreen]}>Accepted <i className='bx bx-check-circle' ></i></label>
        </div>
        <div className={[general.mgL20px]}>
          <div className={[general.fontSize18px, general.fontWgt600].join(" ")}>Contact Requests</div>
          <span className={[general.fontSize12px, general.mgT10px].join(" ")}>
            View recently accepted contact requests.
          </span>

          <div
            className={[
              general.wFitContent,
              general.viewcenter,
              general.fontSize14px,
              general.pd8px12px,
              general.colorWhite,
              general.mgT10px,
              general.borderRadius5px,
              general.bgBlue,
            ].join(" ")}
          >
            View All <i className="bx bx-chevron-right"></i>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default MessageView;
