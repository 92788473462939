import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";

import styles from "../ContainerV1Modal/Container.module.css";

function ContainerV4Modal({ dataList }) {
  return (
    <div className={[styles.containerView]}>
      {dataList.map((data, index) => (
        <Link
          key={index}
          className={[styles.containerItem]}
          to={"p/" + data["property_slug"]}
        >
          <div className={[styles.containerImageBox]}>
            <ImageLoader
              className={[styles.containerImageView]}
              src={data["property_image"]}
              alt={data["property_title"]}
            />
          </div>

          <div className={[styles.containerContentsView]}>
            <span className={[styles.containerContentTitle]}>
              {data["property_title"]}
            </span>
            <div className={[styles.containerContentPriceView]}>
              <span>₹</span>
              {data["property_total_price"]}
              <span className={[styles.mgLR5px]}>|</span>
              {data["property_area_sqft"]}
              <span className={[styles.mgL5px]}>sqft</span>
            </div>

            <div className={[styles.containerContentLocation]}>
              <span>{data["property_location"]}</span>
            </div>
          </div>

          <div className={[styles.viewDetailsBtn]}>View Details</div>
        </Link>
      ))}
    </div>
  );
}

export default ContainerV4Modal;
