import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";

function ChangePassword() {
  const navigate = useNavigate();

  const [inEmail, setInEmail] = useState("");
  const [inOTP, setInOTP] = useState("");
  const [inPassword, setNewPassword] = useState("");
  const [formStage, setFormStage] = useState(1);

  const [pageConst, setConstants] = useState({
    pageTitle: "Change Password",
    isLoadingShow: false,
    isSessionExist: true,
  });

  const [toastDialog, setToastDialog] = useState({
    toastDialog: false,
    toastMessage: "",
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setToastDialog((previousState) => {
      return { ...previousState, toastDialog: data };
    });

    setToastDialog((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const changePassword = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-change-password",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          setFormStage(1);
          setInEmail("");
          setInOTP("");
          setNewPassword("");
          updateToastDialogState(true, "Password Changed!");
        } else if (data.status_code == "invalid_otp") {
          updateToastDialogState(true, "Entered OTP is invalid!");
        } else if (data.status_code == "password_error") {
          updateToastDialogState(true, "Please enter a strong password!");
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (inEmail != "" || inOTP != "" || inPassword != "") {
      updateLoadingStatus(true);
      const formData = {
        SIGNIN_EMAIL: inEmail,
        AUTH_OTP: inOTP,
        NEW_PASSWORD: inPassword,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const sendVerificationCode = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-new-otp",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "otp_required") {
          setFormStage(2);
        } else if (data.status_code == "account_not_exist") {
          updateToastDialogState(
            true,
            "No Account was found with this Email Id!"
          );
        } else {
          updateToastDialogState(
            true,
            "Your Account is not active! Please contact with support team!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (inEmail != "" && formStage == 1) {
      updateLoadingStatus(true);
      const formData = {
        SIGNIN_EMAIL: inEmail,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const continueToChangePassword = () => {
    if (formStage <= 1) {
      sendVerificationCode();
    } else {
      changePassword();
    }
  };

  useEffect(() => {
    // if (getStorage("accountID") == "") {
    //   navigate("/signin", { replace: true });
    // }
  }, [inEmail, inOTP]);

  return (
    <div
      className={[
        general.viewcenter,
        general.appBackground,
        pageConst.isSessionExist == true ? "" : "",
      ].join(" ")}
    >
      <div
        className={[
          general.minheight100vh,
          general.w100,
          general.overflowHidden,
        ].join(" ")}
      >
        <ToastDialog
          intentData={toastDialog}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />

        <div className={[styles.signInView]}>
          <div className={[styles.designView]}></div>
          <div className={[styles.designView2]}></div>
          <div className={[styles.designView3]}></div>

          <div
            className={[
              general.resW400px,
              general.posAbsolute,
              general.top50,
              general.left50,
              general.transformXY50,
              general.pd15px,
              general.borderRadius10px,
              general.bgWhite,
            ].join(" ")}
          >
            <div className={[general.fontWgt600]}>{pageConst.pageTitle}</div>

            <div
              className={[
                general.colView,
                general.inpContainer,
                general.mgT40px,
              ].join(" ")}
            >
              <p className="ft-sz-14 mg-t-10">
                Enter Email ID:{" "}
                <span className={[general.fontWgt600]}>
                  {getStorage("accountEmail")}
                </span>
              </p>

              <div
                className={[general.rowView, general.w100, general.mgT5px].join(
                  " "
                )}
              >
                {formStage == 1 ? (
                  <input
                    type="text"
                    className={[
                      general.w100,
                      general.inpBox,
                      general.fontSize16px,
                    ].join(" ")}
                    value={inEmail}
                    autoComplete="off"
                    onChange={(e) => setInEmail(e.target.value)}
                  ></input>
                ) : (
                  <input
                    type="email"
                    className={[
                      general.w100,
                      general.inpBox,
                      general.fontSize16px,
                    ].join(" ")}
                    value={inEmail}
                    autoComplete="off"
                    readOnly
                    onChange={(e) => setInEmail(e.target.value)}
                  ></input>
                )}

                <div
                  className={[
                    general.w120px,
                    general.viewcenter,
                    general.fontSize13px,
                    general.fontWgt500,
                    general.colorBlue,
                  ].join(" ")}
                  onClick={() => sendVerificationCode()}
                >
                  Send OTP
                </div>
              </div>
            </div>

            <div
              className={[
                general.colView,
                general.inpContainer,
                general.mgT40px,
                formStage <= 1 ? general.hideView : "",
              ].join(" ")}
            >
              <p className={[general.fontSize14px, general.mgT10px].join(" ")}>
                Enter verification Code:{" "}
                <span className={[general.fontWgt600]}>
                  {getStorage("accountEmail")}
                </span>
              </p>

              <div
                className={[general.rowView, general.w100, general.mgT5px].join(
                  " "
                )}
              >
                <input
                  type="number"
                  className={[
                    general.w100,
                    general.inpBox,
                    general.fontSize16px,
                  ].join(" ")}
                  value={inOTP}
                  autoComplete="off"
                  onChange={(e) => setInOTP(e.target.value)}
                ></input>
              </div>
            </div>

            <div
              className={[
                general.colView,
                general.inpContainer,
                general.mgT40px,
                formStage <= 1 ? general.hideView : "",
              ].join(" ")}
            >
              <p className={[general.fontSize14px, general.mgT10px].join(" ")}>
                Enter New Password:{" "}
                <span className={[general.fontWgt600]}>
                  {getStorage("accountEmail")}
                </span>
              </p>

              <div
                className={[general.rowView, general.w100, general.mgT5px].join(
                  " "
                )}
              >
                <input
                  type="text"
                  className={[
                    general.w100,
                    general.inpBox,
                    general.fontSize16px,
                  ].join(" ")}
                  value={inPassword}
                  autoComplete="off"
                  onChange={(e) => setNewPassword(e.target.value)}
                ></input>
              </div>
            </div>

            <div
              className={[
                general.viewcenter,
                general.pd15px30px,
                general.colorWhite,
                general.mgT40px,
                general.borderRadius5px,
                general.bgBlue,
              ].join(" ")}
              onClick={() => continueToChangePassword()}
            >
              {formStage <= 1 ? "Continue" : "Change Password"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
