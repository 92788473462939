import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";

import styles from "../ContainerV9Modal/Container.module.css";

function ContainerV9Modal({ dataList }) {
  return (
    <>
      {dataList != null
        ? dataList.map((data, index) => (
            <Link key={index} className={[styles.containerItem]}>
              <div className={[styles.containerContentsView]}>
                <span className={[styles.titleView]}>
                  {data["container_item_title"]}
                </span>
                <div
                  className="ft-sz-14 mg-t-15"
                  dangerouslySetInnerHTML={{
                    __html: data["container_item_description"],
                  }}
                ></div>
              </div>

              <div className={[styles.containerImageBox]}>
                <ImageLoader
                  className={[styles.containerImageView]}
                  src={data["container_item_image"]}
                  alt={data["container_item_title"]}
                />
              </div>
            </Link>
          ))
        : ""}
    </>
  );
}

export default ContainerV9Modal;
