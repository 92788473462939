import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/dialog";
import MainHeader from "../other-components/MainHeader/header";
import { API_ACCESS_URL } from "../modals/Constants";
import Footer from "../other-components/Footer/footer";
import { deleteStorage, getStorage, setStorage } from "../modals/Storage";
import ContainerV3Modal from "../other-components/ContainerV3Modal";

import LoadingDialog from "../dialogs/LoadingDialog";
import ContainerV1Modal from "../containerModals/ContainerV1Modal/Container";
import ContainerV2Modal from "../containerModals/ContainerV2Modal/Container";
import ContainerV4Modal from "../containerModals/ContainerV4Modal/Container";
import ContainerV5Modal from "../containerModals/ContainerV5Modal";
import ContainerV6Modal from "../containerModals/ContainerV6Modal";
// import Scroll from '../other-components/SmoothScroll';

import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";
import AdvancedSearch from "../other-components/AdvancedSearch/search";
import ChooseOptionsView from "../dialogs/ChooseOptionsDialog/dialog";
import FeatureCards from "../other-components/FeatureCards/featurecards";
import ContainerV7Modal from "../containerModals/ContainerV7Modal/Contaner";
import ContainerV8Modal from "../containerModals/ContainerV8Modal/Contaner";
import ContainerV9Modal from "../containerModals/ContainerV9Modal/Contaner";
import ContainerV10Modal from "../containerModals/ContainerV10Modal/Contaner";
import MessageView from "../other-components/MessageView/messageview";
// import ContainerV9Modal from "../containerModals/ContainerV9Modal/Contaner";

function Home() {
  const navigate = useNavigate();
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [productsList, updateProductsList] = useState(null);

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    isShowFooter: true,
    isLoadingShow: false,
    sliderImages: [],
    recordList: [],
    productsList: [],
    homeUI: [],

    citiesData: [],
    propertyTypesData: [],
    budgetOptionsData: [],
    contactRequestsData: [],
  });

  const [messageView, setMessageView] = useState({
    showMessage: true,
    message: "Hello this is message",
  });

  const [toastDialog, setToastDialog] = useState({
    toastDialog: true,
    toastMessage: "Hello this is message",
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 1,
  });

  const [chooseOptions, setChooseOptions] = useState({
    dialogState: false,
    dialogTitle: "",
    dialogType: 1,
    dialogData: [],
    dialogSelectedOption: "",
  });

  const updateChooseDialog = (state, type, title, data, options) => {
    setChooseOptions((previousState) => {
      return {
        ...previousState,
        dialogState: state,
        dialogTitle: title,
      };
    });

    if (data != "") {
      setChooseOptions((previousState) => {
        return { ...previousState, dialogData: data };
      });
    } else {
      setChooseOptions((previousState) => {
        return { ...previousState, dialogData: [] };
      });
    }

    if (type > 0) {
      setChooseOptions((previousState) => {
        return { ...previousState, dialogType: type };
      });
    }

    if (options != "" && options != undefined) {
      setChooseOptions((previousState) => {
        return {
          ...previousState,
          dialogSelectedOption: options,
        };
      });
    }
  };

  const selectedOption = (data) => {
    if (data == "city") {
      updateChooseDialog(true, 1, "Select City", pageConst.citiesData);
    } else if (data == "propertytypes") {
      updateChooseDialog(
        true,
        2,
        "Property Types",
        pageConst.propertyTypesData
      );
    } else if (data == "budget") {
      updateChooseDialog(true, 2, "Budget", pageConst.budgetOptionsData);
    }
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const setSliderView = (data) => {
    if (data != "") {
      let tempData = [];

      for (let i = 0; i < data.length; i++) {
        tempData.push(data[i]["slider_img"]);
      }

      setConstants((previousState) => {
        return { ...previousState, sliderImages: tempData };
      });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setToastDialog((previousState) => {
      return { ...previousState, toastDialog: data };
    });

    setToastDialog((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateUI = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      let tempContainerClass = data[i]["container_class"];
      let isDetailsAvailable = false;
      if (
        tempContainerClass != "container-v-9" &&
        tempContainerClass != "container-v-10"
      ) {
        isDetailsAvailable = true;
      }

      tempData.push(
        <div
          className={[general.w100, general.colView, general.mgT50px].join(" ")}
        >
          {isDetailsAvailable ? (
            <div className={[general.containerViewDetails]}>
              <div>
                <span>{data[i]["container_title"]}</span>
                <p>{data[i]["container_description"]}</p>
              </div>

              <div className={[general.containerOptions]}>
                {/* <div>
                  View More
                  <img
                    src={require("../media/icons/arrow_right_up_red_icon.png")}
                    alt="icon"
                  />
                </div> */}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={[general.pd10px, general.mgT20px].join(" ")}>
            {data[i]["container_class"] == "container-v-1" ? (
              <ContainerV1Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-2" ? (
              <ContainerV2Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-3" ? (
              <ContainerV3Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-4" ? (
              <ContainerV4Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-5" ? (
              <ContainerV5Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-6" ? (
              <ContainerV6Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-7" ? (
              <ContainerV7Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-8" ? (
              <ContainerV8Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-9" ? (
              <ContainerV9Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-10" ? (
              <ContainerV10Modal dataList={data[i]["items"]} />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, homeUI: tempData };
    });
  };

  const updateCategoryArr = (data) => {
    setStorage("categoryArr", JSON.stringify(data));
  };

  function loadHomeScreenData() {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-home-screen",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          if (data.account_status == "inactive") {
            navigate("/account-update-instruction", { replace: true });
          } else if (data.account_status == "unauthorized") {
            if (deleteStorage()) {
              navigate("/signin", { replace: true });
            }
          }
          setStorage("paymentURL", data.payment_url);
          setStorage("footerShortInfo", data.footer_short_info);
          setStorage("footerContactInfo", data.footer_contact_info);

          setStorage("socialWhatsapp", data.social_whatsapp);
          setStorage("socialInstagram", data.social_instagram);
          setStorage("socialYoutube", data.social_youtube);
          setStorage("socialFacebook", data.social_facebook);

          updateUI(data.containersData);
          updateProductsList(data.productData);
          updateCategoryArr(data.categoryData);

          setConstants((previousState) => {
            return { ...previousState, citiesData: data.citiesData };
          });

          setConstants((previousState) => {
            return {
              ...previousState,
              propertyTypesData: data.propertyTypesData,
              budgetOptionsData: data.budgetOptionsData,
              contactRequestsData: data.contactRequests,
            };
          });

          setIsDataLoaded(true);
          updateLoadingStatus(false);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (!isDataLoaded) {
      updateLoadingStatus(true);
      const formData = { ACCOUNT_ID: getStorage("accountID") };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const handleScrollBar = (e) => {
    const target = e.target;
  };

  useEffect(() => {
    loadHomeScreenData();
  }, [pageConst.sliderImages, slideShowIndex]);

  return (
    <div className={[general.viewcenter, general.appBackground].join(" ")}>
      <div
        className="h-100vh w-100 ovf-scrl-y"
        onScroll={(e) => handleScrollBar(e)}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />
        <ChooseOptionsView
          intentData={chooseOptions}
          updateState={updateChooseDialog}
        />

        <div
          className={[
            general.w100,
            general.colView,
            general.alignItemsCenter,
            general.minheight100vh,
            ,
          ].join(" ")}
        >
          <MainHeader intentData={mainHeader} />

          <div className={[general.w100, styles.homeContentsView, ,].join(" ")}>
            <div className={[general.w100, general.pd10px].join(" ")}>
              <AdvancedSearch
                intentData={chooseOptions.dialogSelectedOption}
                updateState={selectedOption}
              />
            </div>

            <div
              className={[
                general.colView,
                general.respWidth,
                general.bgWhite,
                general.mg0pxauto,
              ].join(" ")}
            >
              {pageConst.contactRequestsData.length > 0 ? (
                <MessageView intentData={messageView} />
              ) : (
                ""
              )}

              {pageConst.homeUI}
            </div>
          </div>
        </div>

        <Footer intentData={pageConst} />
      </div>
    </div>
  );
}

export default Home;
