import React, { useEffect, useState } from "react";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import LoadingDialog from "../dialogs/LoadingDialog";
import MainHeader from "../other-components/MainHeader/header";
import general from "../css/General.module.css";

function PrivacyPolicy() {
  const [detailsInfo, setDetailsInfo] = useState("");

  const [pageConst, setConstants] = useState({
    pageTitle: "Privacy Policy",
    isLoadingShow: false,
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const getInfo = () => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-docs",
          },
          body: JSON.stringify(formData),
        });

        updateLoadingStatus(false);
        const data = await res.json();
        if (data.status_code == "success") {
          setDetailsInfo(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    const formData = {
      DOCS_TYPE: "PRIVACY_POLICY_INFO",
    };
    requestAPI(API_ACCESS_URL, formData);
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <LoadingDialog intentData={pageConst} />

        <div className={[general.w100, general.colView].join(" ")}>
          <MainHeader intentData={mainHeader} />

          <div
            className={[
              general.colView,
              general.respWidth,
              general.bgWhite,
              general.pd10px,
              general.mg0pxauto,
              general.mgT30px,
            ].join(" ")}
          >
            <h1 className={[general.fontSize25px]}>{pageConst.pageTitle}</h1>
            <div
              className={[general.mgT20px].join(" ")}
              dangerouslySetInnerHTML={{
                __html: detailsInfo,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
