import React from "react";
import { Link } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import styles from "../DialogZoomImage/Styles.module.css";

const DialogZoomImage = ({ intentData, updateState }) => {
  return (
    <div
      className={[
        styles.dialogView,
        `${intentData.showDialog ? "" : styles.dialogViewHide}`,
      ].join(" ")}
    >
      <div className={[styles.dialogContainer]}>
        <div className={[styles.dismissBtn]} onClick={() => updateState(false)}>
          <span>✕</span>
        </div>
        <TransformWrapper>
          <TransformComponent>
            <img src={intentData.dialogImage} alt="image" />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
  );
};

export default DialogZoomImage;
