import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ToastDialog from "../dialogs/ToastDialog/dialog";
import { API_ACCESS_URL, copyText, getFullURL } from "../modals/Constants";
import Footer from "../other-components/Footer/footer";
import MainHeader from "../other-components/MainHeader/header";
import { deleteStorage, getStorage, setStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";
import ImageLoader from "../other-components/ImageLoader";

import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import DialogZoomImage from "../dialogs/DialogZoomImage/dialog";
import ViewImages from "../dialogs/ViewImages/dialog";
import ContactDialog from "../dialogs/ContactDialog/dialog";
import MapView from "../other-components/MapView/mapview";

function ViewProduct() {
  const navigate = useNavigate();
  let { urlParam } = useParams();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [slideShowIndex, setSlideShowIndex] = useState(0);

  const [isDataLoaded, setIsDataLoaded] = useState("");
  const delay = 5000;

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    slideShowImage: "",
    isLoadingShow: true,
    isShowFooter: true,
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const [itemDetails, setItemDetails] = useState({
    itemId: "",
    itemDeveloper: "",
    itemName: "",
    itemLocation: "",
    itemDescription: "",
    productBrand: "",
    itemPrice: 0,
    itemSqftPrice: 0,
    productOfferedPrice: 0,
    productPricingHtml: "",
    itemBuildupArea: "",
    itemCarpetArea: "",
    itemConfiguration: "",
    itemFaceDirection: "",
    itemFurnishStatus: "",
    itemCarParking: "",
    itemParkingCharges: "",
    itemBalconies: "",
    itemBathrooms: "",
    itemFloors: "",
    itemTotalFloors: "",
    itemConstructionAge: "",
    itemConstructionStatus: "",
    itemLicense: "",
    itemMapLocation: [],
    itemSlugURL: "",
    itemReturnPolicy: "",
    productSizes: [],
    itemTags: [],
    itemImageList: [],
  });

  const [dialogV2, setDialogV2] = useState({
    showDialog: false,
    dialogTitle: "Select Size!",
    sizesList: [],
  });

  const [viewImages, setViewImages] = useState({
    showDialog: false,
  });

  const [dialogZoomImage, setDialogZoomImage] = useState({
    showDialog: false,
    dialogImage: "",
  });

  const [toastDialog, setToastDialog] = useState({
    toastDialog: false,
    toastMessage: "",
  });

  const [contactDialog, setContactDialog] = useState({
    showDialog: false,
  });

  const updateContactDialog = (data, extra) => {
    setContactDialog((previousState) => {
      return { ...previousState, showDialog: data };
    });

    if (extra == "submitted") {
      updateToastDialogState(true, "Request Submitted!");
    } else if (extra == "already_pending") {
      updateToastDialogState(true, "Request Under Pending!");
    }
  };

  const updateViewImages = (data) => {
    if (data == true || data == false) {
      setViewImages((previousState) => {
        return { ...previousState, showDialog: data };
      });
    }
  };

  const updateZoomDialogImage = (data) => {
    setDialogZoomImage((previousState) => {
      return { ...previousState, dialogImage: data };
    });
  };

  const updateZoomDialog = (data) => {
    setDialogZoomImage((previousState) => {
      return { ...previousState, showDialog: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setToastDialog((previousState) => {
      return { ...previousState, toastDialog: data };
    });

    setToastDialog((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const shareItem = () => {
    if (window.Handle && itemDetails.itemName!="") {
      window.Handle.shareContent(itemDetails.itemName, getFullURL(), "Checkout this beautiful property which I have found on 👇");
    } else {
      copyText(getFullURL());
      updateToastDialogState(true, "Link Copied!");
    }
  };

  const convertArrStringToInt = (stringArray) => {
    let numberArray = [];

    for (let i = 0; i < stringArray.length; i++) {
      numberArray.push(parseFloat(stringArray[i]));
    }

    return numberArray;
  };

  function getProductDetails() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-product-details",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();

        if (data.status_code == "success") {
          if (data.account_status == "inactive") {
            navigate("/account-update-instruction", { replace: true });
          } else if (data.account_status == "unauthorized") {
            if (deleteStorage()) {
              navigate("/signin", { replace: true });
            }
          }

          let latLonArray = [];
          if (data.productData[0]["property_map_location"].length > 0) {
            latLonArray = convertArrStringToInt(
              data.productData[0]["property_map_location"]
            );
          }

          setItemDetails({
            ...itemDetails,
            itemId: data.productData[0]["property_id"],
            itemDeveloper: data.productData[0]["property_developer"],
            itemName: data.productData[0]["property_title"],
            itemLocation: data.productData[0]["property_location"],
            itemPrice: data.productData[0]["property_price"],
            itemSqftPrice: data.productData[0]["property_sqft_price"],
            itemDescription: data.productData[0]["property_description"],
            itemImageList: data.productData[0]["property_images"],

            itemBuildupArea: data.productData[0]["property_buildup_area"],
            itemCarpetArea: data.productData[0]["property_carpet_area"],
            itemConfiguration: data.productData[0]["property_configurations"],
            itemFaceDirection: data.productData[0]["property_face_direction"],
            itemFurnishStatus: data.productData[0]["property_furnish_status"],
            itemCarParking: data.productData[0]["property_car_parking"],
            itemParkingCharges: data.productData[0]["property_parking_charges"],
            itemBalconies: data.productData[0]["property_balconies"],
            itemBathrooms: data.productData[0]["property_bathrooms"],
            itemFloors: data.productData[0]["property_floors"],
            itemTotalFloors: data.productData[0]["property_total_floors"],
            itemConstructionAge:
              data.productData[0]["property_construction_age"],
            itemConstructionStatus:
              data.productData[0]["property_construction_status"],
            itemLicense: data.productData[0]["property_license"],
            itemMapLocation: latLonArray,
            itemTags: data.productData[0]["property_tags"],
          });

          setIsDataLoaded(true);
          updateLoadingStatus(false);
        }

        setPageTitle(data.page_title);
        setPageDescription(data.page_description);
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (!isDataLoaded) {
      updateLoadingStatus(true);
      fecthApiData(
        API_ACCESS_URL +
          "?ACCOUNT_ID=" +
          getStorage("accountID") +
          "&PRODUCT=" +
          urlParam
      );
    }
  }

  useEffect(() => {
    if (urlParam != "") {
      getProductDetails();

      resetTimeout();

      if (itemDetails.itemImageList.length > 0) {
        timeoutRef.current = setTimeout(
          () =>
            setSlideShowIndex((prevIndex) =>
              prevIndex === itemDetails.itemImageList.length - 1
                ? 0
                : prevIndex + 1
            ),
          delay
        );
      }
    }

    return () => {
      resetTimeout();
    };
  }, [itemDetails.itemImageList, slideShowIndex]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <div className={[general.viewcenter, general.appBackground].join(" ")}>
        <div className="h-100vh w-100 ovf-scrl-y">
          <ToastDialog
            intentData={toastDialog}
            updateState={updateToastDialogState}
          />
          <LoadingDialog intentData={pageConst} />
          <ContactDialog
            intentData={contactDialog}
            updateState={updateContactDialog}
            requestedProperty={itemDetails.itemId}
          />
          <DialogZoomImage
            intentData={dialogZoomImage}
            updateState={updateZoomDialog}
          />
          <ViewImages
            imageList={itemDetails.itemImageList}
            intentData={viewImages}
            updateState={updateViewImages}
          />

          <MainHeader intentData={mainHeader} />

          <div className="min-h-100vh">
            <div
              className={[
                general.colView,
                styles.viewDetailsContainer,
                !isDataLoaded ? general.hideView : "",
              ].join(" ")}
            >
              <div
                className={[
                  general.w100,
                  general.bgWhite,
                  !isDataLoaded ? general.hideView : "",
                ].join(" ")}
              >
                <div
                  className={[
                    general.respWidth,
                    general.colView,
                    general.pd15px0px,
                    general.mg0pxauto,
                  ].join(" ")}
                >
                  <div className={[general.mgT10px]}>
                    <label className="ft-sz-14">Home / {urlParam}</label>
                  </div>

                  <div
                    className={[
                      styles.shortDetailsView,
                      general.mgT10px,
                      general.mgB10px,
                    ].join(" ")}
                  >
                    <div>
                      <h1 className={[general.fontSize26px]}>
                        {itemDetails.itemName}
                      </h1>
                      <div
                        className={[general.fontSize14px, general.mgT15px].join(
                          " "
                        )}
                      >
                        By
                        <span
                          className={[
                            general.mgL10px,
                            general.fontSize14px,
                            general.fontWgt600,
                            general.colorBlue,
                          ].join(" ")}
                        >
                          {itemDetails.itemDeveloper}
                        </span>
                      </div>
                      <div
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        {itemDetails.itemLocation}
                      </div>
                    </div>

                    <div>
                      <div
                        className={[
                          general.fontSize18px,
                          general.fontWgt600,
                          general.mgT20px,
                        ].join(" ")}
                      >
                        ₹{itemDetails.itemPrice}
                      </div>
                      <div
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        {itemDetails.itemBuildupArea} sq.ft Build Up Area
                      </div>
                    </div>
                  </div>

                  <div className={[styles.respImageGallaryView].join(" ")}>
                    <Splide
                      options={{
                        perPage: 1,
                        pagination: false,
                        gap: "1rem",
                      }}
                    >
                      {itemDetails.itemImageList.map((data, index) => (
                        <SplideSlide>
                          <ImageLoader
                            className="lazy-loading"
                            src={data}
                            onClick={() => [
                              updateZoomDialogImage(data),
                              updateZoomDialog(true),
                            ]}
                            alt="product-image"
                          />
                        </SplideSlide>
                      ))}
                    </Splide>
                  </div>

                  <div
                    className={[
                      general.mgT30px,
                      styles.bigImageGallaryView,
                    ].join(" ")}
                    onClick={() => updateViewImages(true)}
                  >
                    <div>
                      <img
                        src={itemDetails.itemImageList[0]}
                        alt={itemDetails.itemName}
                      />
                    </div>

                    <div>
                      <img
                        src={itemDetails.itemImageList[1]}
                        alt={itemDetails.itemName}
                      />
                      <section>
                        <img
                          src={itemDetails.itemImageList[2]}
                          alt={itemDetails.itemName}
                        />
                        <span>
                          +<br></br>
                          {itemDetails.itemImageList.length} more
                        </span>
                      </section>
                    </div>
                  </div>

                  <div
                    className={[
                      general.rowView,
                      general.flexWrapWrap,
                      general.gap15px,
                      general.mgT15px,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        general.viewcenter,
                        general.pd15px,
                        general.flexDirectionCol,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          general.fontSize15px,
                          general.fontWgt600,
                        ].join(" ")}
                      >
                        {itemDetails.itemBuildupArea} sq.ft
                      </span>
                      <span
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        Build Up Area
                      </span>
                    </div>

                    <div
                      className={[
                        general.viewcenter,
                        general.pd15px,
                        general.flexDirectionCol,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          general.fontSize15px,
                          general.fontWgt600,
                        ].join(" ")}
                      >
                        ₹{itemDetails.itemSqftPrice}/sq.ft
                      </span>
                      <span
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        Avg. Price
                      </span>
                    </div>

                    <div
                      className={[
                        general.viewcenter,
                        general.pd15px,
                        general.flexDirectionCol,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          general.fontSize15px,
                          general.fontWgt600,
                        ].join(" ")}
                      >
                        {itemDetails.itemConfiguration}
                      </span>
                      <span
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        Configuration
                      </span>
                    </div>

                    <div
                      className={[
                        general.viewcenter,
                        general.pd15px,
                        general.flexDirectionCol,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          general.fontSize15px,
                          general.fontWgt600,
                        ].join(" ")}
                      >
                        10 Years Old
                      </span>
                      <span
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        Age of property
                      </span>
                    </div>

                    <div
                      className={[
                        general.viewcenter,
                        general.pd15px,
                        general.flexDirectionCol,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          general.fontSize15px,
                          general.fontWgt600,
                        ].join(" ")}
                      >
                        {itemDetails.itemConstructionStatus}
                      </span>
                      <span
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        Possession status
                      </span>
                    </div>

                    <div
                      className={[
                        general.viewcenter,
                        general.pd15px,
                        general.flexDirectionCol,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          general.fontSize15px,
                          general.fontWgt600,
                        ].join(" ")}
                      >
                        {itemDetails.itemFurnishStatus}
                      </span>
                      <span
                        className={[general.fontSize14px, general.mgT5px].join(
                          " "
                        )}
                      >
                        Furnish Status
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={[
                  general.respWidth,
                  general.colView,
                  general.mg0pxauto,
                ].join(" ")}
              >
                <div className={[styles.viewMoreDetailsContainer]}>
                  <div className={[styles.detailsViewLeft].join(" ")}>
                    <div
                      className={[
                        general.borderRadius5px,
                        general.bgWhite,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          general.pd20px15px,
                          general.borderBottom1px,
                        ].join(" ")}
                      >
                        <h2 className={[general.fontSize16px]}>Overview</h2>
                      </div>

                      <div className={[general.pd15px]}>
                        <table className={[general.mgT15px]}>
                          <tbody>
                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-building-house"></i>
                                </div>
                                <div>
                                  <div>Buildup Area</div>
                                  <div>{itemDetails.itemBuildupArea} sq.ft</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-area"></i>
                                </div>
                                <div>
                                  <div>Carpet Area</div>
                                  <div>{itemDetails.itemCarpetArea} sq.ft</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-purchase-tag"></i>
                                </div>
                                <div>
                                  <div>Avg. Price</div>
                                  <div>{itemDetails.itemPrice}</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-compass"></i>
                                </div>
                                <div>
                                  <div>Configuration</div>
                                  <div>{itemDetails.itemConfiguration}</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-compass"></i>
                                </div>
                                <div>
                                  <div>Facing</div>
                                  <div>{itemDetails.itemFaceDirection}</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-home-alt-2"></i>
                                </div>
                                <div>
                                  <div>Furnished Status</div>
                                  <div>{itemDetails.itemFurnishStatus}</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-car"></i>
                                </div>
                                <div>
                                  <div>Car Parking</div>
                                  <div>{itemDetails.itemCarParking}</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-car"></i>
                                </div>
                                <div>
                                  <div>Bathrooms</div>
                                  <div>{itemDetails.itemBathrooms}</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-car"></i>
                                </div>
                                <div>
                                  <div>Balconies</div>
                                  <div>{itemDetails.itemBalconies}</div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-car"></i>
                                </div>
                                <div>
                                  <div>Floor</div>
                                  <div>
                                    {itemDetails.itemFloors +
                                      " (out of " +
                                      itemDetails.itemTotalFloors +
                                      " Floors)"}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-car"></i>
                                </div>
                                <div>
                                  <div>Parking Charges</div>
                                  <div>{itemDetails.itemParkingCharges}</div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-receipt"></i>
                                </div>
                                <div>
                                  <div>License</div>
                                  <div>{itemDetails.itemLicense}</div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div>
                                  <i className="bx bx-time-five"></i>
                                </div>
                                <div>
                                  <div>Age Of Construction</div>
                                  <div>{itemDetails.itemConstructionAge}</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div
                          className={[
                            general.viewcenter,
                            general.flexWrapWrap,
                            general.mgT20px,
                          ].join(" ")}
                        >
                          <div
                            className={[
                              general.rowView,
                              general.pd10px30px,
                              general.mg5px,
                              general.colorBlue,
                              general.borderRadius5px,
                              general.bgCustomColor2,
                            ].join(" ")}
                            onClick={() => shareItem()}
                          >
                            <i className="bx bx-share-alt"></i>
                            <span className={[general.mgL10px]}>Share</span>
                          </div>

                          <div
                            className={[
                              general.rowView,
                              general.pd10px30px,
                              general.mg5px,
                              general.colorWhite,
                              general.borderRadius5px,
                              general.bgBlue,
                            ].join(" ")}
                            onClick={() => updateContactDialog(true, "")}
                          >
                            Ask For Details
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={[
                        general.borderRadius5px,
                        general.mgT20px,
                        general.bgWhite,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          general.pd20px15px,
                          general.borderBottom1px,
                        ].join(" ")}
                      >
                        <h2 className={[general.fontSize16px]}>
                          About this project
                        </h2>
                      </div>

                      <div
                        className={[
                          general.pd15px,
                          general.fontSize13px,
                          general.lineHeight24px,
                        ].join(" ")}
                        dangerouslySetInnerHTML={{
                          __html: itemDetails.itemDescription,
                        }}
                      ></div>
                    </div>

                    {itemDetails.itemMapLocation.length > 1 ? (
                      <div
                        className={[
                          general.borderRadius5px,
                          general.mgT20px,
                          general.bgWhite,
                        ].join(" ")}
                      >
                        <div
                          className={[
                            general.pd20px15px,
                            general.borderBottom1px,
                          ].join(" ")}
                        >
                          <h2 className={[general.fontSize16px]}>
                            Explore Neighbourhood - Map View
                          </h2>
                        </div>

                        <div
                          className={[
                            general.w100,
                            general.textAlignCenter,
                            general.pd20px,
                          ].join(" ")}
                        >
                          {itemDetails.itemMapLocation.length > 0 ? (
                            <MapView data={itemDetails.itemMapLocation} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className={[
                      general.posSticky,
                      general.top80px,
                      general.alignSelfFlexStart,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        general.pd15px,
                        general.fontSize13px,
                        general.borderRadius10px,
                        general.bgCustomColor5,
                        general.mgT15px,
                      ].join(" ")}
                    >
                      <i className="bx bx-bulb"></i>
                      <span className={[general.mgL5px]}>
                        Great choice! Better priced property in this area
                      </span>
                    </div>

                    <div
                      className={[
                        general.pd15px,
                        general.mgT15px,
                        general.borderRadius10px,
                        general.bgCustomColor3,
                      ].join(" ")}
                    >
                      <div className={[general.rowView]}>
                        <img
                          className={[general.height25px]}
                          src={require("../media/icons/account_white_icon.png")}
                          alt="icon"
                        />
                        <div
                          className={[general.colView, general.mgL10px].join(
                            " "
                          )}
                        >
                          <div
                            className={[
                              general.colorWhite,
                              general.fontWgt600,
                            ].join(" ")}
                          >
                            <span>Hi&#128075;</span>
                            <span className={[general.mgL5px]}>
                              {getStorage("accountFullName")}
                            </span>
                          </div>
                          <span
                            className={[
                              general.mgT5px,
                              general.fontSize13px,
                              general.colorWhite,
                            ].join(" ")}
                          >
                            Hope you’re having a great day!
                          </span>
                        </div>
                      </div>

                      <div className={[general.mgT30px]}>
                        <Link
                          className={[
                            general.textDecoNone,
                            general.viewcenter,
                            general.pd10px30px,
                            general.fontWgt600,
                            general.colorBlue,
                            general.mgT30px,
                            general.borderRadius5px,
                            general.bgWhite,
                          ].join(" ")}
                          onClick={() => updateContactDialog(true, "")}
                        >
                          Get Contact Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer intentData={pageConst} />
        </div>
      </div>
    </>
  );
}

export default ViewProduct;
