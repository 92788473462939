import React from "react";
import styles from "../ToastDialog/Styles.module.css";

const toastDialog = ({ intentData, updateState }) => {
  return (
    <div
      className={[
        styles.dialogView,
        intentData.toastDialog ? "" : styles.dialogViewHide,
      ].join(" ")}
      onClick={() => updateState(false, "")}
    >
      <div className={[styles.dialogContainer]}>
        <div className="pd-15 ft-sz-18 ft-wgt-500">{intentData.toastMessage}</div>

        <div className="br-a-l-black"></div>

        <div className="v-center pd-15 ft-sz-18 cl-blue">OK</div>
      </div>
    </div>
  );
};

export default toastDialog;
