import React from "react";
import { Link } from "react-router-dom";

import styles from "../ContainerV10Modal/Container.module.css";

function ContainerV10Modal({ dataList }) {
  return (
    <>
      {dataList != null
        ? dataList.map((data, index) => (
            <div
              key={index}
              className={[styles.containerItem]}
            >
              <div className={[styles.containerContentsView]}>
                <div
                  className={[styles.titleView]}
                  dangerouslySetInnerHTML={{
                    __html: data["container_item_title"],
                  }}
                ></div>

                <Link className={[styles.mgT20px, styles.actionBtn].join(" ")} to={"/"+data["container_item_slug"]}>
                  Get Started
                  <img
                    src={require("../../media/icons/arrow_right_up_white_icon.png")}
                    alt="icon"
                  />
                </Link>
              </div>

              <div className={[styles.containerContentsView2]}>
                <div className={[styles.colView]}>
                  <div className={[styles.rowView]}>
                    <img
                      className={[styles.height20px]}
                      src={require("../../media/icons/experience_icon.png")}
                      alt="icon"
                    />
                    <span
                      className={[
                        styles.fontSz20px,
                        styles.fontWgt600,
                        styles.mgL5px,
                      ].join(" ")}
                    >
                      15
                    </span>
                  </div>
                  <span
                    className={[styles.fontSz12px, styles.mgT5px].join(" ")}
                  >
                    Years of Experience
                  </span>
                </div>

                <div className={[styles.colView]}>
                  <div className={[styles.rowView]}>
                    <img
                      className={[styles.height20px]}
                      src={require("../../media/icons/property_icon.png")}
                      alt="icon"
                    />
                    <span
                      className={[
                        styles.fontSz20px,
                        styles.fontWgt600,
                        styles.mgL5px,
                      ].join(" ")}
                    >
                      25K
                    </span>
                  </div>
                  <span
                    className={[styles.fontSz12px, styles.mgT5px].join(" ")}
                  >
                    Property Ready
                  </span>
                </div>

                <div className={[styles.colView]}>
                  <div className={[styles.rowView]}>
                    <img
                      className={[styles.height20px]}
                      src={require("../../media/icons/property_icon.png")}
                      alt="icon"
                    />
                    <span
                      className={[
                        styles.fontSz20px,
                        styles.fontWgt600,
                        styles.mgL5px,
                      ].join(" ")}
                    >
                      25K
                    </span>
                  </div>
                  <span
                    className={[styles.fontSz12px, styles.mgT5px].join(" ")}
                  >
                    Property Ready
                  </span>
                </div>

                <div className={[styles.colView]}>
                  <div className={[styles.rowView]}>
                    <img
                      className={[styles.height20px]}
                      src={require("../../media/icons/account_icon.png")}
                      alt="icon"
                    />
                    <span
                      className={[
                        styles.fontSz20px,
                        styles.fontWgt600,
                        styles.mgL5px,
                      ].join(" ")}
                    >
                      23M
                    </span>
                  </div>
                  <span
                    className={[styles.fontSz12px, styles.mgT5px].join(" ")}
                  >
                    Total Seller
                  </span>
                </div>
              </div>
            </div>
          ))
        : ""}
    </>
  );
}

export default ContainerV10Modal;
