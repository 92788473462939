import crypto from "crypto-js";

export const WEBSITE_NAME = "Siri Homes";
export const WEBSITE_URL = "https://sirihomes.in";
// export const WEBSITE_URL = "http://localhost:3000";

export const API_ACCESS_URL = "https://api.sirihomes.in/router/";
export const IP_ACCESS_URL = "https://api64.ipify.org/?format=json";
export const LIVE_CHAT_URL = "";

export const CUSTOM_USER_AGENT = "NINJA_CRIPT_1.0.0.1";


export function redirectTo(url) {
    window.location.replace(url);
}

export function openNewPage(url) {
  if(url!="none"){
    window.location.href =url;
  }
}

export function getFullURL() {
  return window.location.href;
}

export function getHostName() {
  return window.location.protocol+'//'+window.location.host;
}

export function getURLParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function getInternetStatus(){
    if(navigator.onLine){
        return true;
    }else{
        return false;
    }
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}


export function getRandomString(length) {
    let characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function copyText(text){

    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.opacity = "0"; 
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (err) {
      return true;
    }

    return true;
}

export function generateAuthToken(token,ip){
    return crypto.SHA256(token).toString()+'i16ni14j1'+ip+'5s3189pt';
}
  