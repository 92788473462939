import React, { useEffect, useState, useRef } from "react";

const UploadShowImage = ({ onImageUpdate, onImageChanges, showNewMessage }) => {
  // Create a reference to the hidden file input element
  const [maxFileSize, setMaxFileSize] = useState(3);
  const [imagesArray,setImagesArray] = useState([]);

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    let selectedFiles = event.target.files;

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].size > maxFileSize * 1024 * 1024) {
        showNewMessage(true, "Oops! you can't upload more than 3mb file!");
      } else if (imagesArray.length > 10) {
        showNewMessage(true, "Oops! you can't upload more than 10 files!");
      }else{
        setImagesArray([...selectedFiles]);
        break;
      }
    }
  };

  useEffect(() => {
    if (imagesArray.length < 1) return;
    const newImageUrls = [];
    imagesArray.forEach((image) => newImageUrls.push(image));
    onImageUpdate(newImageUrls);
  }, [imagesArray]);

  return (
    <>
      <div className="dragDropUploadContainer" onClick={handleClick}>
        <div className="col-view v-center dragDropUploadWrapper">
          <div className="col-view a-center">
            <img
              className="h-40-p"
              src={require("../media/icons/photo_icon.png")}
              alt="icon"
            />
            <span className="ft-sz-18 ft-wgt-600 mg-t-25">Upload Here</span>
            <span className="ft-sz-13 mg-t-10">
              *Kindly upload high-resolution images for improved product
              quality.
            </span>
          </div>
        </div>
      </div>

      <input
        type="file"
        multiple="multiple"
        accept="image/jpeg,image/png,image/jpg"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
    </>
  );
};

export default UploadShowImage;