import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import styles from "../FeatureCards/featurecards.module.css";

function FeatureCards() {
  return (
    <div className={[styles.featureCardsView]}>
      <Splide
        options={{
          // perPage: 4,
          arrows: false,
          fixedWidth: "18rem",
          gap: "1rem",
          pagination: false,
          // breakpoints: {
          //   1024: {
          //     perPage: 3,
          //   },
          //   650: {
          //     perPage: 2,
          //   },
          //   380: {
          //     perPage: 1,
          //   },
          // },
        }}
      >
        <SplideSlide>
          <Link className={[styles.featureCardItem]} to={"/"}>
            <div className={[styles.featureCardItemImageBox]}>
            <img
              src={require("../../media/icons/star_white_icon.png")}
              alt="icon"
            />
            </div>

            <span>Top Rated</span>
          </Link>

          <Link className={[styles.featureCardItem]} to={"/"}>
            <div className={[styles.featureCardItemImageBox]}>
            <img
              src={require("../../media/icons/trend_white_icon.png")}
              alt="icon"
            />
            </div>

            <span>Popular</span>
          </Link>
        </SplideSlide>
      </Splide>
    </div>
  );
}

export default FeatureCards;
