import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/dialog";
import "../../MainStyle.css";
import {
  WEBSITE_NAME,
  API_ACCESS_URL,
  generateAuthToken,
} from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";

function PasswordVerification() {
  const navigate = useNavigate();

  const [inPassword, setInPassword] = useState("");

  const [pageConst, setConstants] = useState({
    pageTitle: "Verification",
    isLoadingShow: false,
    toastDialogShow: false,
    toastTimeAvail: 7,
    toastMessage: "",
    isSessionExist: true,
  });

  const [toastDialog, setToastDialog] = useState({
    toastDialog: false,
    toastMessage: "",
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setToastDialog((previousState) => {
      return { ...previousState, toastDialog: data };
    });

    setToastDialog((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const validatePassword = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-verify-password",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "signin_successful") {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);

          setStorage("accountEmail", data.account_email);
          setStorage("accountMobile", data.account_mobilenum);
          setStorage("accountFullName", data.account_fullname);

          navigate("/", { replace: true });
        } else if (data.status_code == "onboard_required") {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);

          setStorage("accountEmail", data.account_email);
          setStorage("accountMobile", data.account_mobilenum);
          setStorage("accountFullName", data.account_fullname);

          navigate("/onboard", { replace: true });
        } else if (data.status_code == "password_error") {
          updateToastDialogState(true, "Entered Password is incorrect!");
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (
      getStorage("accountID") != "" &&
      getStorage("accountEmail") != "" &&
      inPassword.length > 2
    ) {
      updateLoadingStatus(true);
      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
        SIGNIN_EMAIL: getStorage("accountEmail"),
        SIGNIN_PASSWORD: inPassword,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  useEffect(() => {
    if (getStorage("accountID") == "") {
      navigate("/signin", { replace: true });
    }
  }, []);

  return (
    <div
      className={[
        general.viewcenter,
        general.appBackground,
        pageConst.isSessionExist == true ? "" : "",
      ].join(" ")}
    >
      <div
        className={[
          general.minheight100vh,
          general.w100,
          general.overflowHidden,
        ].join(" ")}
      >
        <ToastDialog
          intentData={toastDialog}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />

        <div className={[styles.signInView]}>
          <div className={[styles.designView]}></div>
          <div className={[styles.designView2]}></div>
          <div className={[styles.designView3]}></div>

          <div
            className={[
              general.resW400px,
              general.posAbsolute,
              general.top50,
              general.left50,
              general.transformXY50,
              general.pd15px,
              general.borderRadius10px,
              general.bgWhite,
            ].join(" ")}
          >
            <div className={[general.fontWgt600]}>Password Verification</div>

            <div
              className={[
                general.colView,
                general.inpContainer,
                general.mgT40px,
              ].join(" ")}
            >
              <p className={[general.fontSize14px, general.mgT10px].join(" ")}>
                Please Enter the Password:{" "}
                <span className={[general.fontWgt600]}>
                  {getStorage("accountEmail")}
                </span>
              </p>

              <div
                className={[general.rowView, general.w100, general.mgT5px].join(
                  " "
                )}
              >
                <input
                  type="password"
                  className={[
                    general.w100,
                    general.inpBox,
                    general.fontSize16px,
                  ].join(" ")}
                  value={inPassword}
                  autoComplete="off"
                  onChange={(e) => setInPassword(e.target.value)}
                ></input>
              </div>
            </div>

            <div
              className={[
                general.viewcenter,
                general.pd15px30px,
                general.colorWhite,
                general.mgT40px,
                general.borderRadius5px,
                general.bgBlue,
              ].join(" ")}
              onClick={() => validatePassword()}
            >
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordVerification;
