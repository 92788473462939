import React from "react";
import styles from "../ConfirmDialog/dialog.module.css";

const ConfirmDialog = ({ intentData, updateState }) => {
  return (
    <div
      className={[
        styles.dialogView,
        intentData.showDialog && intentData.dialogMessage
          ? ""
          : styles.dialogViewHide,
      ].join(" ")}
    >
      <div className={[styles.dialogContainer]}>
        <div className="pd-15 ft-sz-18 ft-wgt-500">{intentData.dialogMessage}</div>

        <div className="br-a-l-black mg-t-15"></div>

        <div className="v-center pd-15 ft-sz-18 cl-blue" onClick={() => updateState(true, "")}>Yes Correct</div>
        <div className="br-a-l-black"></div>
        <div className="v-center pd-15 ft-sz-18 cl-red" onClick={() => updateState(false, "")}>Cancel</div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
