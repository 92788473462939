import React, { useState, useEffect } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { getStorage, setStorage, deleteStorage } from "../../modals/Storage";
import {
  openNewPage,
  WEBSITE_LOGO_ORIGINAL,
  WEBSITE_NAME,
} from "../../modals/Constants";
import general from "../../css/General.module.css";
import styles from "../MainHeader/header.module.css";

const MainHeader = ({ intentData }) => {
  const navigate = useNavigate();
  const [showSideMenu, setSideMenu] = useState(false);

  const navigateToLogin = () => {
    if (getStorage("accountID") == "" || getStorage("accountSecret") == "") {
      navigate("/signin", { replace: false });
    }
  };

  const handleSubmit = (e) => {
    if (e.target[0].value != "") {
      navigate("/search/" + e.target[0].value, { replace: false });
    }
    e.preventDefault();
  };

  const updateSideMenu = () => {
    if (showSideMenu) {
      setSideMenu(false);
    } else {
      setSideMenu(true);
    }
  };

  return (
    <header className={[styles.mainHeader, styles.colView].join(" ")}>
      <div
        className={[
          styles.desktopHeaderView,
          styles.width100,
          styles.rowView,
          styles.jusContentSpaceBet,
          styles.alignItemsCenter,
          styles.pd10px25px,
        ].join(" ")}
      >
        <div className={[styles.rowView]}>
          <label
            className="mOpenMenu mg-r-15 v-center"
            onClick={() => updateSideMenu()}
          >
            <img
              className="h-22-p"
              src={require("../../media/icons/menu_black_icon.png")}
              alt="icon"
            />
          </label>

          <Link className={[styles.mLogoOriginal]} to={"/"}>
            {/* {WEBSITE_NAME} */}
            <img src={require("../../media/icons/app_icon.png")} alt="icon" />
          </Link>
        </div>

        <div
          className={[
            styles.rowView,
            styles.categoryHeaderView,
            intentData.headerType >= 2 ? general.hideView : "",
          ].join(" ")}
        >
          {getStorage("categoryArr") != ""
            ? JSON.parse(getStorage("categoryArr")).map((data, index) => (
                <Link
                  key={index}
                  to={"/search/" + data["category_slug"]}
                  className={[styles.rowView, styles.alignItemsCenter].join(
                    " "
                  )}
                >
                  <span>{data["category_name"]}</span>
                </Link>
              ))
            : ""}
        </div>

        <div className={[styles.rowView]}>
          <Link
            className={[
              styles.rowView,
              styles.alignItemsCenter,
              general.fontWgt500,
              general.fontSize15px,
              styles.mgR10px,
              styles.signInSignupBtn,
            ].join(" ")}
            to={!getStorage("accountID") ? "/signin" : "/account"}
          >
            <img
              className={[styles.height15px, styles.mgR5px].join(" ")}
              src={require("../../media/icons/account_icon.png")}
              alt="icon"
            />
            <span>
              {!getStorage("accountID") ? "Signin/Signup" : "Account"}
            </span>
          </Link>

          <Link
            className={[
              styles.rowView,
              styles.alignItemsCenter,
              general.fontWgt500,
              general.fontSize15px,
              styles.addPropertyBtn,
            ].join(" ")}
            to={"/add-property"}
          >
            Add Property
            <img
              className={[styles.height8px]}
              src={require("../../media/icons/arrow_right_up_icon.png")}
              alt="icon"
            />
          </Link>
        </div>
      </div>

      <div
        className={`pos-fx pos-top-0 pos-btm-0 w-100 h-100vh z-i-1000 bg-l-black ovf-hidden ${
          showSideMenu ? "pos-left-0" : "pos-left--100"
        }`}
        onClick={() => updateSideMenu()}
      >
        <div
          className={`pos-fx pos-top-0 pos-left-0 pos-btm-0 w-310-p h-100vh col-view trn-05 bg-white ovf-scrl-y hide-sb ${
            showSideMenu ? "pos-left-0" : "pos-left--100"
          }`}
        >
          <div
            className="w-100 col-view mg-t-65 pd-30-15"
            onClick={() => navigateToLogin()}
          >
            <Link
              className={[
                general.textDecoNone,
                general.rowView,
                general.colorBlack,
              ].join(" ")}
            >
              <div
                className={[
                  general.heightWidth35px,
                  general.viewcenter,
                  general.mgR10px,
                  general.bgBlue,
                  general.borderRadius50,
                  getStorage("accountID") == "" ? general.hideView : "",
                ].join(" ")}
              >
                <h5
                  className={[general.fontSize18px, general.colorWhite].join(
                    " "
                  )}
                >
                  {getStorage("accountFullName").charAt(0).toUpperCase()}
                </h5>
              </div>
              <div>
                <h5 className="ft-sz-18">
                  {getStorage("accountID") != "" &&
                  getStorage("accountSecret") != ""
                    ? "Hey " + getStorage("accountFullName").split(" ")[0]
                    : "Welcome, Guest"}
                </h5>
                <span
                  className={[
                    general.fontSize14px,
                    general.fontWgt600,
                    general.mgT10px,
                    general.colorBlue,
                  ].join(" ")}
                >
                  {getStorage("accountID") != ""
                    ? "Go to Account"
                    : "Log In/ Sign Up"}
                </span>
              </div>
            </Link>
          </div>

          <div className="line-hv-grey mg-t-10"></div>

          <div className={`mg-t-10`}>
            {getStorage("categoryArr") != ""
              ? JSON.parse(getStorage("categoryArr")).map((data, index) => (
                  <Link
                    key={index}
                    className={`display-block txt-deco-n cl-black pd-15`}
                    to={"/search/" + data["category_slug"]}
                  >
                    <span className="ft-sz-14 ft-wgt-600">
                      {data["category_name"]}
                    </span>
                  </Link>
                ))
              : ""}
          </div>

          <div
            className={`${
              getStorage("accountID") == "" || getStorage("accountSecret") == ""
                ? "hide-v"
                : ""
            }`}
          >
            <div className="line-hv-grey mg-t-10"></div>

            <Link
              className={`display-block txt-deco-n cl-black pd-15 mg-t-10`}
              to={"/account"}
            >
              <span className="ft-sz-14 ft-wgt-600">My Account</span>
            </Link>

            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              to={"/my-posts"}
            >
              <span className="ft-sz-14 ft-wgt-600">My Posts</span>
            </Link>
          </div>

          <div className="line-hv-grey mg-t-10"></div>

          <div className="mg-t-10">
            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              to={"/help-support"}
            >
              <span className="ft-sz-14 ft-wgt-600">Help & Support</span>
            </Link>

            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              to={"/privacy-policy"}
            >
              <span className="ft-sz-14 ft-wgt-600">Privacy & Policy</span>
            </Link>

            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              to={"/refund-policy"}
            >
              <span className="ft-sz-14 ft-wgt-600">Refund Policy</span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
