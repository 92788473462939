import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import general from "../../css/General.module.css";
import styles from "../AdvancedSearch/search.module.css";

function AdvancedSearch({ intentData, updateState }) {
  const navigate = useNavigate();
  // const [searchCity, setOptionCity] = useState("");
  const [searchOption, setSearchOption] = useState("buy");
  const [searched, setInpSearched] = useState("");

  function changeHandler(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      searchQuery();
    }
  }

  const searchQuery = () => {
    let searchQuery = "";

    if (searched != "" || intentData!="") {
      if (searched!="" && intentData != "") {
        searchQuery = intentData + "/" + searched;
      } else if (searched!="") {
        searchQuery = searched;
      } else if (intentData!="") {
        searchQuery = intentData;
      }

      navigate("/search/" + searchOption + "/" + searchQuery, {
        replace: false,
      });
    }
  };

  const selectedOption = (data) => {
    updateState(data);
  };

  return (
    <div className={[styles.advancedSearchView]}>
      <h1>Let's search for property</h1>

      <div className={[styles.searchInputContainer]}>
        <div className={[styles.searchInputOptions]}>
          <div
            className={[searchOption == "buy" ? styles.activeView : ""]}
            onClick={() => setSearchOption("buy")}
          >
            Buy
          </div>
          <div
            className={[searchOption == "rent" ? styles.activeView : ""]}
            onClick={() => setSearchOption("rent")}
          >
            Rent
          </div>
        </div>

        <div className={[styles.searchInputView]}>
          <div onClick={() => selectedOption("city")}>
            <p>{intentData != "" ? intentData : "Select City"}</p>
            <img
              src={require("../../media/icons/angle_down_icon.png")}
              alt="icon"
            />
          </div>

          <input
            type="text"
            placeholder="Search for locality, landmark, project"
            autoComplete="off"
            onChange={(e) => setInpSearched(e.target.value)}
            onKeyDown={changeHandler}
          ></input>

          <div
            className={[styles.searchBtn, general.bgCustomColor1].join(" ")}
            onClick={() => searchQuery()}
          >
            <span>Search</span>
            <img
              src={require("../../media/icons/search_white_icon.png")}
              alt="icon"
            />
          </div>
        </div>
        {/* 
        <div onClick={() => updateState("propertytypes")}>
          <p>Property Type</p>
          <img
            src={require("../../media/icons/angle_down_icon.png")}
            alt="icon"
          />
        </div>

        <div onClick={() => updateState("budget")}>
          <p>Budget</p>
          <img
            src={require("../../media/icons/angle_down_icon.png")}
            alt="icon"
          />
        </div> */}
      </div>

      {/* <div>
        <div onClick={() => updateState("city")}>
          <p>Select City</p>
          <img
            src={require("../../media/icons/angle_down_icon.png")}
            alt="icon"
          />
        </div>
        
      </div> */}
    </div>
  );
}

export default AdvancedSearch;
