import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import styles from "../ContainerV8Modal/Container.module.css";

function ContainerV8Modal({ dataList }) {
  return (
    <Splide
      options={{
        perPage: 4,
        gap: "1rem",
        pagination: false,
        breakpoints: {
          800: {
            perPage: 3,
          },
          580: {
            perPage: 2,
          },
          370: {
            perPage: 1,
          },
        },
      }}
    >
      {dataList.map((data, index) => (
        <SplideSlide>
          <Link
            key={index}
            className={[styles.containerItem]}
            to={"p/" + data["property_slug"]}
          >
            <div className={[styles.containerImageBox]}>
              <ImageLoader
                className={[styles.containerImageView]}
                src={data["property_image"]}
                alt={data["property_title"]}
              />
            </div>

            <div className={[styles.containerContentsView]}>
              <span className={[styles.titleView]}>{data["property_title"]}</span>
              <span className={[styles.locationTxtview]}>{data["property_location"]}</span>

              <span className={[styles.priceTxtView]}>₹{data["property_price"]}</span>
            </div>
          </Link>
        </SplideSlide>
      ))}
    </Splide>
  );
}

export default ContainerV8Modal;
