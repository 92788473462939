import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <GoogleOAuthProvider clientId="1002027090125-v51dcbc7d8g71tuj8b87j4cnodm2fed1.apps.googleusercontent.com">
      <App />
      </GoogleOAuthProvider>
);

