import React, { useEffect, useState } from "react";
import styles from "../ChooseOptionsDialog/dialog.module.css";

const ChooseOptionsDialog = ({ intentData, updateState }) => {
  return (
    <div
      className={[
        styles.dialogView,
        !intentData.dialogState ? styles.hideView : "",
      ].join(" ")}
    >
      <div className={[styles.dialogViewContents]}>
        <div className={[styles.dialogViewTitle]}>
          <p>{intentData.dialogTitle}</p>
          <img
            src={require("../../media/icons/close_icon.png")}
            alt="icon"
            onClick={() => updateState(false, "", "", "", "")}
          />
        </div>

        <div
          className={[
            intentData.dialogType == 1
              ? styles.dialogViewContentsList
              : styles.dialogViewContentsItemList,
          ]}
        >
          {intentData.dialogData != null
            ? intentData.dialogData.map((data, index) => (
                <div
                  key={index}
                  className={[styles.dialogViewContentsItem]}
                  onClick={() =>
                    updateState(false, "", "", "", data["item_name"])
                  }
                >
                  <img src={data["item_image"]} alt="icon" />
                  <span>{data["item_name"]}</span>
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default ChooseOptionsDialog;
