import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/dialog";
import "../../MainStyle.css";
import {
  WEBSITE_NAME,
  API_ACCESS_URL,
  generateAuthToken,
} from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

import { useGoogleLogin } from "@react-oauth/google";

import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";

function Login() {
  const navigate = useNavigate();

  const [tempGoogleAuthData, setTempGoogleAuthData] = useState(null);

  const continueWithGoogle = () =>{
    if(window.Handle){
      window.Handle.googleLogin("from web");
    }else{
      signInWithGoogle();
    }
  }

  window.androidGoogleLoginResponse = (data) => {
    if(window.Handle && data!=""){
      const tempArray = data.split(",");
      let tempDisplayName = tempArray[0];
      let tempEmail = tempArray[1];
      validateSigninWithGmail(tempDisplayName, tempEmail);
    }
  }

  const signInWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setTempGoogleAuthData(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const [inEmailId, setInEmailId] = useState("");

  const [pageConst, setConstants] = useState({
    pageTitle: "Login",
    inPassword: "",
    isLoadingShow: false,
    toastDialogShow: false,
    toastTimeAvail: 7,
    toastMessage: "",
    isSessionExist: true,
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const validateSigninWithEmail = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-signin",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (
          data.status_code == "account_created" ||
          data.status_code == "onboard_required"
        ) {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);
          navigate("/onboard", { replace: true });
        } else if (data.status_code == "signin_successful") {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);

          setStorage("accountEmail", data.account_email);
          setStorage("accountMobile", data.account_mobilenum);
          setStorage("accountFullName", data.account_fullname);
          navigate("/", { replace: true });
        } else if (data.status_code == "otp_required") {
          setStorage("accountID", data.account_id);
          setStorage("accountEmail", data.account_email);
          navigate("/otp-verification", { replace: true });
        } else if (data.status_code == "password_required") {
          setStorage("accountID", data.account_id);
          setStorage("accountEmail", data.account_email);
          navigate("/password-verification", { replace: true });
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (inEmailId.length >= 5) {
      updateLoadingStatus(true);
      const formData = {
        SIGNIN_EMAIL: inEmailId,
        SIGNIN_MOBILE: "",
        SIGNIN_FULLNAME: "",
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const validateSigninWithGmail = (displayName, emailId) => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-signin",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (
          data.status_code == "account_created" ||
          data.status_code == "onboard_required"
        ) {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);
          navigate("/onboard", { replace: true });
        } else if (data.status_code == "signin_successful") {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);

          setStorage("accountEmail", data.account_email);
          setStorage("accountMobile", data.account_mobilenum);
          setStorage("accountFullName", data.account_fullname);
          navigate("/", { replace: true });
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (emailId!="") {
      const formData = {
        SIGNIN_EMAIL: emailId,
        SIGNIN_MOBILE: "",
        SIGNIN_FULLNAME: displayName,
        SIGNIN_WITH_GOOGLE: "true",
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const getEmailInfo = () => {
    async function requestAPI(url) {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tempGoogleAuthData.access_token}`,
            Accept: "application/json",
          },
        });

        setTempGoogleAuthData(null);

        const data = await res.json();
        let tempDisplayName = data.given_name + " " + data.family_name;
        let tempEmail = data.email;

        validateSigninWithGmail(tempDisplayName,tempEmail);
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    updateLoadingStatus(true);
    requestAPI(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tempGoogleAuthData.access_token}`
    );
  };

  useEffect(() => {
    if (getStorage("accountID") != "" && getStorage("accountSecret") != "") {
      navigate("/", { replace: true });
    } else {
      if (tempGoogleAuthData != null) {
        getEmailInfo();
      }
    }
  }, [tempGoogleAuthData]);

  return (
    <div
      className={[
        general.viewcenter,
        general.appBackground,
        pageConst.isSessionExist == true ? "" : "",
      ].join(" ")}
    >
      <div
        className={[
          general.minheight100vh,
          general.w100,
          general.overflowHidden,
        ].join(" ")}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />

        <div className={[styles.signInView]}>
          <div className={[styles.designView]}></div>
          <div className={[styles.designView2]}></div>
          <div className={[styles.designView3]}></div>

          <div className={[styles.signInOverview]}>
            <div className={[styles.signInOverviewTitle]}>
              Looking for the right buyer?
            </div>

            <div className={[general.rowView, general.mgT30px].join(" ")}>
              <div
                className={[
                  general.viewcenter,
                  general.heightWidth23px,
                  general.borderRadius50,
                  general.fontSize12px,
                  general.colorWhite,
                  general.bgGreen,
                ].join(" ")}
              >
                1
              </div>

              <span
                className={[
                  general.fontSize14px,
                  general.colorWhite,
                  general.mgL10px,
                ].join(" ")}
              >
                Login With your Email
              </span>
            </div>

            <div className={[general.rowView, general.mgT15px].join(" ")}>
              <div
                className={[
                  general.viewcenter,
                  general.heightWidth23px,
                  general.borderRadius50,
                  general.fontSize12px,
                  general.colorWhite,
                  general.bgGreen,
                ].join(" ")}
              >
                2
              </div>

              <span
                className={[
                  general.fontSize14px,
                  general.colorWhite,
                  general.mgL10px,
                ].join(" ")}
              >
                Add property details
              </span>
            </div>

            <div className={[general.rowView, general.mgT15px].join(" ")}>
              <div
                className={[
                  general.viewcenter,
                  general.heightWidth23px,
                  general.borderRadius50,
                  general.fontSize12px,
                  general.colorWhite,
                  general.bgGreen,
                ].join(" ")}
              >
                3
              </div>

              <span
                className={[
                  general.fontSize14px,
                  general.colorWhite,
                  general.mgL10px,
                ].join(" ")}
              >
                Property activated in just 30 min
              </span>
            </div>
          </div>

          <div className={[styles.formSubmitView]}>
            <div className={[general.fontWgt600]}>
              Get Started with your Email.
            </div>

            <div
              className={[
                general.colView,
                general.inpContainer,
                general.mgT40px,
              ].join(" ")}
            >
              <span
                className={[general.fontSize13px, general.fontWgt500].join(" ")}
              >
                Enter Email*
              </span>
              <div
                className={[general.rowView, general.w100, general.mgT5px].join(
                  " "
                )}
              >
                <input
                  type="email"
                  className={[
                    general.w100,
                    general.inpBox,
                    general.fontSize16px,
                  ].join(" ")}
                  value={inEmailId}
                  autoComplete="off"
                  onChange={(e) => setInEmailId(e.target.value)}
                ></input>
              </div>
            </div>

            <div
              className={[
                general.viewcenter,
                general.pd15px30px,
                general.colorWhite,
                general.mgT40px,
                general.borderRadius5px,
                general.bgBlue,
              ].join(" ")}
              onClick={() => validateSigninWithEmail()}
            >
              Continue
            </div>

            <div
              className={[
                general.colView,
                general.fontSize12px,
                general.mgT15px,
              ].join(" ")}
            >
              I don't remember password?
              <Link
                className={[
                  general.textDecoNone,
                  general.fontSize14px,
                  general.fontWgt500,
                  general.colorBlue,
                ].join(" ")}
                to={"/change-password"}
              >
                Change Password
              </Link>
            </div>

            <div
              className={[
                general.viewcenter,
                general.fontSize13px,
                general.mgT40px,
              ].join(" ")}
            >
              --- Or connect with ----
            </div>

            <div
              className={[
                general.viewcenter,
                general.mg0pxauto,
                general.mgT40px,
                styles.googleLoginBtn,
                pageConst.isLoadingShow == true ? general.hideView : "",
              ].join(" ")}
              onClick={() => continueWithGoogle()}
            >
              <img
                className="h-w-22"
                src={require("../media/icons/google_icon.png")}
              />
              <span className="ft-wgt-600 mg-l-10">Google</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
