import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

const ComponentResize = () => {
  const map = useMap();

  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  return null;
};

const customIcon = new Icon({
  iconUrl: require("../../media/icons/home_marker_icon.png"),
  iconSize: [38, 38], // size of the icon
});

const MapView = (position) => {

  return (
    <MapContainer
    	style={{
    		height: "100%",
    		width: "100%",
    	}}
    	center={position.data}
    	attributionControl={true}
    	zoom={15}
    	minZoom={3}
    	scrollWheelZoom={false}
    >
    	<ComponentResize />
    	<TileLayer
    		attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    		url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    	/>
    	<Marker position={position.data} icon={customIcon}>
    		<Popup>
    			Property
    		</Popup>
    	</Marker>
    </MapContainer>
  );
};

export default MapView;
