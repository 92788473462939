import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../../other-components/ImageLoader";

import general from "../../css/General.module.css";
import styles from "../ContainerV2Modal/Container.module.css";

function ContainerV2Modal({ dataList }) {
  return (
    <div className={[styles.containerView]}>
      {dataList.map((data, index) => (
        <Link
          key={index}
          className={[styles.containerItem]}
          to={"search/" + data["property_slug"]}
        >
          <div className={[styles.containerImageBox]}>
            <ImageLoader
              className={[styles.containerImageView]}
              src={data["property_image"]}
              alt={data["property_title"]}
            />

            <span className={[styles.ratingTagView]}>
              {data["property_tags"] == "POPULAR" ? (
                <img
                  src={require("../../media/icons/trend_white_icon.png")}
                  alt="icon"
                />
              ) : data["property_tags"] == "NEW LAUNCHED" ? (
                <img
                  src={require("../../media/icons/clock_white_icon.png")}
                  alt="icon"
                />
              ) : (
                <img
                  src={require("../../media/icons/star_white_icon.png")}
                  alt="icon"
                />
              )}

              {data["property_tags"]}
            </span>

            <div className={[styles.forwardPostView]}>
              <img
                src={require("../../media/icons/forward_icon.png")}
                alt="icon"
              />
            </div>
          </div>

          <div className={[styles.containerContentsView]}>
            <span className={[styles.containerContentTitle]}>
              {data["property_title"]}
            </span>
            <div className={[styles.containerContentPriceView]}>
              <span>₹</span>
              {data["property_total_price"]}
              <span className={[styles.mgLR5px]}>|</span>
              {data["property_area_sqft"]}
              <span className={[styles.mgL5px]}>sqft</span>
            </div>

            <div className={[styles.containerContentLocation]}>
              <span>{data["property_location"]}</span>
            </div>

            <div
              className={[
                styles.containerContactBtn,
                general.bgCustomColor1,
              ].join(" ")}
            >
              Contact Builder
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default ContainerV2Modal;
