import React from "react";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import styles from "../ViewImages/Styles.module.css";
import ImageLoader from "../../other-components/ImageLoader";

const ViewImages = ({ imageList, intentData, updateState }) => {
  return (
    <div
      className={[
        styles.dialogView,
        `${intentData.showDialog ? "" : styles.dialogViewHide}`,
      ].join(" ")}
    >
      <div className={[styles.dialogContainer]}>
        <div className={[styles.dismissBtn]} onClick={() => updateState(false)}>
          <span>✕</span>
        </div>

        <Splide
          options={{
            perPage: 1,
            pagination: false,
            gap: "1rem",
          }}
        >
          {imageList.map((data, index) => (
            <SplideSlide>
              <ImageLoader
                className="lazy-loading"
                src={data}
                onClick={() => [updateState(data)]}
                alt="product-image"
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default ViewImages;
