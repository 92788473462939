import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/dialog";
import MainHeader from "../other-components/MainHeader/header";
import { API_ACCESS_URL } from "../modals/Constants";
import Footer from "../other-components/Footer/footer";
import { getStorage, setStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";
import ContainerV11Modal from "../containerModals/ContainerV11Modal/Contaner";

import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";
import ChooseOptionsDialog from "../dialogs/ChooseOptionsDialog/dialog";

function Search() {
  const navigate = useNavigate();

  const [urlParam, setUrlParam] = useState("");
  const [prevSearchParams, setPrevSearchParams] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [inputName, setInputName] = useState("");

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    isShowFooter: true,
    isLoadingShow: false,
    searchedResults: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const [chooseOptions, setChooseOptions] = useState({
    dialogState: false,
    dialogTitle: "",
    dialogType: 1,
    dialogData: [],
    dialogSelectedOption: "",
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateSearchResults = (data) => {
    setConstants((previousState) => {
      return { ...previousState, searchedResults: data };
    });
  };

  function searchProducts(searched) {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-search-items",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          setIsDataLoaded(true);
          updateSearchResults(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (!isDataLoaded && searched != "" && searched != "def") {
      updateLoadingStatus(true);
      const formData = { SEARCHED: searched };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  // const loadMoreProduct = () =>{
  //   const fecthApiData = async (url) => {

  //     try {
  //       const res = await fetch(url);
  //       const data = await res.json();

  //       if(data.status_code=="success"){
  //         let myCartItems = data.my_cart_items;
  //         let service_app_status = data.service_app_status;
  //         let service_app_download_url = data.app_download_url;
  //         let service_active_pg = data.service_active_pg;

  //         setCookie("noCartItems", myCartItems, 30);
  //         setCookie("appDownloadURL", service_app_download_url, 30);
  //         setCookie("activePG", service_active_pg, 30);

  //         updateRecordList(data.productList);
  //         setSliderView(data.slideShowList);
  //         setAllCategories(data.allCategories);
  //         updateFooterStatus(true);

  //         if(service_app_status=="OFF"){
  //           navigate('/um', { replace: true });
  //         }
  //       }

  //     } catch (error) {
  //     }
  //   }

  //   if(pageConst.recordList.length <= 0){
  //     fecthApiData(API_ACCESS_URL+"request-account-info.php?USER_ID="+getCookie("uid"));
  //   }
  // }

  const handleScrollBar = (e) => {
    const target = e.target;
    // console.log(target.scrollHeight +"/"+ target.scrollTop +"/"+ target.clientHeight);
    // console.log(target.scrollHeight - target.scrollTop === target.clientHeight);
  };

  const handleSubmit = (e) => {
    if (e.target[0].value != "") {
      navigate("/search/" + e.target[0].value, { replace: false });

      setIsDataLoaded(false);
      setPrevSearchParams(e.target[0].value);
    }
    e.preventDefault();
  };

  const getURLParams = () => {
    var str = decodeURI(window.location.href);
    var pattern = "search/";

    var truncateBefore = function (str, pattern) {
      return str.slice(str.indexOf(pattern) + pattern.length);
    };
    let searchedParam = truncateBefore(str, pattern);
    setUrlParam(searchedParam);
    searchProducts(searchedParam);
  };

  useEffect(() => {
    getURLParams();
  }, [pageConst.searchedResults, prevSearchParams]);

  return (
    <div className={[general.viewcenter, general.appBackground].join(" ")}>
      <div
        className="h-100vh w-100 ovf-scrl-y"
        onScroll={(e) => handleScrollBar(e)}
      >
        <LoadingDialog intentData={pageConst} />

        <div className="w-100 col-view a-center">
          <MainHeader intentData={mainHeader} />

          <div
            className={[
              general.minheight100vh,
              general.respWidth2,
              general.mgT30px,
              general.mg0pxauto,
            ].join(" ")}
          >
            <div className={[general.pd15px]}>
              <h1
                className={`ft-sz-20 ft-wgt-500 ${
                  urlParam != "def" ? "" : "hide-v"
                }`}
              >
                Search Result For: {urlParam}
              </h1>

              <div className={[general.rowView, general.mgT10px].join(" ")}>
                <span className={[general.fontSize13px]}>Sort By:</span>
                <div
                  className={[
                    general.wFitContent,
                    general.pd5px15px,
                    general.mgL10px,
                    general.borderRadius5px,
                    general.bgWhite,
                  ].join(" ")}
                >
                  Area
                  <img
                    className={[general.height15px, general.mgL10px].join(" ")}
                    src={require("../media/icons/angle_down_icon.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>

            <div className={[styles.searchViewContents]}>
              <div>
                <ContainerV11Modal dataList={pageConst.searchedResults} />
              </div>

              <Link
                className={[
                  general.posSticky,
                  general.top80px,
                  general.alignSelfFlexStart,
                  general.textDecoNone,
                  general.pd15px,
                  general.borderRadius10px,
                  general.bgCustomColor3,
                ].join(" ")}
                to={"/add-property"}
              >
                <h2
                  className={[general.fontSize16px, general.colorWhite].join(
                    " "
                  )}
                >
                  Are you a property owner?
                </h2>
                <div className={[general.mgT30px]}>
                  <div
                    className={[
                      general.viewcenter,
                      general.pd10px30px,
                      general.fontWgt600,
                      general.colorBlue,
                      general.mgT30px,
                      general.borderRadius5px,
                      general.bgWhite,
                    ].join(" ")}
                  >
                    Add property for free
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <Footer intentData={pageConst} />
      </div>
    </div>
  );
}

export default Search;
