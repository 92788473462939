import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./MainStyle.css";
import Home from "./components/screens/Home";
import Account from "./components/screens/Account";
import ViewProduct from "./components/screens/ViewProduct";
import Login from "./components/screens/Login";
import ChangePassword from "./components/screens/ChangePassword";
import Search from "./components/screens/Search";
import UnderMaintenance from "./components/screens/UnderMaintenance";
import OnBoard from "./components/screens/OnBoard";
import OtpVerification from "./components/screens/OtpVerification";
import PasswordVerification from "./components/screens/PasswordVerification";
import AddProperty from "./components/screens/AddProperty";
import MyPosts from "./components/screens/MyPosts";
import PrivacyPolicy from "./components/screens/PrivacyPolicy";
import RefundPolicy from "./components/screens/RefundPolicy";
import AboutUs from "./components/screens/AboutUs";
import AccountUpdateInfo from "./components/screens/AccountUpdateInfo";
import MyRequests from "./components/screens/MyRequests";
import HelpSupport from "./components/screens/HelpSupport";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="p/:urlParam/" element={<ViewProduct />} />

          <Route path="/add-property" element={<AddProperty />} />
          <Route path="/my-properties" element={<MyPosts />} />
          <Route path="/my-requests" element={<MyRequests />} />

          <Route path="/signin" element={<Login />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route
            path="/password-verification"
            element={<PasswordVerification />}
          />
          <Route path="/onboard" element={<OnBoard />} />
          <Route path="/update-account" element={<OnBoard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/account-update-instruction" element={<AccountUpdateInfo />} />

          <Route path="/change-password" element={<ChangePassword />} />

          <Route
            path="search/:urlParam/:urlParam/:urlParam"
            element={<Search />}
          />
          <Route path="search/:urlParam/:urlParam" element={<Search />} />
          <Route path="search/:urlParam" element={<Search />} />

          <Route path="/um" element={<UnderMaintenance />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/help-support" element={<HelpSupport />} />

          {/* default page */}
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
